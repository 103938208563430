import { Edge, Node, XYPosition } from '@xyflow/react';
import { map, reject } from 'lodash';
import { UUID } from '@senrasystems/senra-ui';
import {
  Bundle,
  Graph,
  isLayoutNode,
  isLayoutPointNode,
  isMeasurableNode,
  isMeasurementEdge,
  isSegmentEdge,
} from '../types.ts';
import { SegmentEdge, SegmentEdgeData } from '../components/edges/SegmentEdge/SegmentEdge.tsx';
import { hasBundle } from './bundles.ts';
import { MeasurementEdgeData } from '../components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { findNodeById } from '../graph/NodeFactory.ts';
import { calculateMidpoint } from './geometry.ts';

/**
 * Get all layout nodes connected to a given node.
 * @param nodes - Array of nodes in the graph.
 * @param edges - Array of edges in the graph.
 * @param nodeId - ID of the node to find connected design part nodes for.
 * @returns Array of design part nodes connected to the specified node.
 */
export const getConnectedLayoutNodes = (nodes: Node[], edges: Edge[], nodeId: UUID): Node[] => {
  return nodes.filter(
    (node) =>
      isLayoutNode(node) &&
      edges.some(
        (edge) =>
          isSegmentEdge(edge) &&
          ((edge.source === nodeId && edge.target === node.id) || (edge.target === nodeId && edge.source === node.id)),
      ),
  );
};

/**
 * Finds all measurement edges connected to a node.
 * @param edges
 * @param nodeId
 */
export const getConnectedMeasurements = (edges: Edge[], nodeId: UUID): Edge<MeasurementEdgeData>[] => {
  return edges.filter(
    (edge): edge is Edge<MeasurementEdgeData> =>
      isMeasurementEdge(edge) && (edge.source === nodeId || edge.target === nodeId),
  );
};

/**
 * Finds all segments connected to a node. If a bundleId is provided, returns only segments that include the bundleId.
 * @param edges - Array of edges in the graph.
 * @param nodeId - The ID of the node to find connected edges for.
 * @param bundleId - (Optional) The ID of the bundle to filter edges by.
 * @returns An array of connected segment edges, optionally filtered by bundleId.
 */
export const getConnectedSegments = (edges: Edge[], nodeId: UUID, bundleId?: string): Edge<SegmentEdgeData>[] => {
  return edges.filter((edge): edge is Edge<SegmentEdgeData> => {
    // Check if the edge is a segment and is connected to the specified node
    const isConnectedToNode = isSegmentEdge(edge) && (edge.source === nodeId || edge.target === nodeId);

    if (bundleId) {
      // If bundleId is provided, ensure the edge's bundles include the specified bundleId
      return isConnectedToNode && hasBundle((edge.data as SegmentEdgeData).bundles, bundleId);
    }

    // Return all connected segment edges if no bundleId is specified
    return isConnectedToNode;
  });
};

/**
 * Determines the position of the new control point on the segment.
 * @param nodes
 * @param edge
 */
export const getMidpoint = (nodes: Node[], edge: Edge): XYPosition => {
  // Calculate the midpoint for the new node
  const sourceNode = findNodeById(nodes, edge.source);
  const targetNode = findNodeById(nodes, edge.target);

  if (!sourceNode || !targetNode) {
    // eslint-disable-next-line no-console
    console.warn(`Source or target node not found for edge ${edge.id}.`);
    return { x: 0, y: 0 };
  }

  return calculateMidpoint(sourceNode.position, targetNode.position);
};

/**
 * Finds an existing direct segment between two nodes.
 * @param edges - The array of edges to search through.
 * @param sourceId - The ID of the source node.
 * @param targetId - The ID of the destination node.
 * @returns The found edge, or undefined if not found.
 */
export const findDirectSegment = (edges: Edge[], sourceId: UUID, targetId: UUID): SegmentEdge | undefined => {
  const edge = edges.find(
    (edge) =>
      isSegmentEdge(edge) &&
      ((edge.source === sourceId && edge.target === targetId) ||
        (edge.source === targetId && edge.target === sourceId)),
  );

  return edge as SegmentEdge | undefined;
};

/**
 * Checks if a node is directly connected to another node via any edge.
 */
export const isDirectlyConnected = (sourceId: UUID, targetId: UUID, edges: Edge[], bundle?: Bundle): boolean => {
  return edges.some((edge) => {
    // Check if the edge is a 'Segment' edge and is directly connected between the source and target nodes
    const isDirectConnection =
      isSegmentEdge(edge) &&
      ((edge.source === sourceId && edge.target === targetId) ||
        (edge.source === targetId && edge.target === sourceId));

    // If bundle is provided, check that the edge contains the specified bundle
    if (bundle) {
      const hasMatchingBundle = (edge.data as SegmentEdgeData).bundles.some(
        (b) =>
          (b.sourceDesignPartId === bundle.sourceDesignPartId &&
            b.destinationDesignPartId === bundle.destinationDesignPartId) ||
          (b.sourceDesignPartId === bundle.destinationDesignPartId &&
            b.destinationDesignPartId === bundle.sourceDesignPartId),
      );
      return isDirectConnection && hasMatchingBundle;
    }

    // If no bundle is provided, only consider the direct connection
    return isDirectConnection;
  });
};

/**
 * Finds a measurement edge between two nodes.
 * @param edges
 * @param sourceId
 * @param targetId
 */
export const findMeasurementEdge = (
  edges: Edge[],
  sourceId: UUID,
  targetId: UUID,
): Edge<MeasurementEdgeData> | undefined => {
  return edges.find(
    (edge): edge is Edge<MeasurementEdgeData> =>
      isMeasurementEdge(edge) &&
      ((edge.source === sourceId && edge.target === targetId) ||
        (edge.source === targetId && edge.target === sourceId)),
  );
};

/**
 * Finds a path between two nodes in the graph. A path is considered valid if there is a sequence of edges connecting
 * the source node to the target node. If a bundle is provided, the path must involve edges that contain the specified
 * bundle.
 * @param nodes - The list of nodes in the graph.
 * @param edges - The list of edges in the graph.
 * @param sourceId - The ID of the source node.
 * @param targetId - The ID of the target node.
 * @param isValidNode - A function that determines if a node is valid for the path.
 * @param bundle - (Optional) The bundle to check for when determining if two nodes are connected.
 * If no bundle is provided, the function checks for any valid path between the nodes.
 * @returns An object containing a boolean indicating if a path exists, and an array of edges representing the path.
 */
export const findPath = (
  nodes: Node[],
  edges: Edge[],
  sourceId: UUID,
  targetId: UUID,
  isValidNode: (node: Node) => boolean,
  bundle?: Bundle,
): { exists: boolean; pathEdges: Edge<SegmentEdgeData>[] } => {
  const visited = new Set<string>();
  const stack: string[] = [sourceId];
  const predecessorMap: Record<string, Edge<SegmentEdgeData> | null> = {};

  // Initialize predecessors
  nodes.forEach((node) => (predecessorMap[node.id] = null));

  while (stack.length > 0) {
    const currentNodeId = stack.pop();
    if (!currentNodeId) continue;

    if (currentNodeId === targetId) {
      // Path exists, reconstruct the path
      const pathEdges: Edge<SegmentEdgeData>[] = [];
      let nodeId = targetId;

      // Trace back using the predecessor map
      while (predecessorMap[nodeId]) {
        const edge = predecessorMap[nodeId];
        if (!edge) {
          throw new Error(`Edge for node ${nodeId} is unexpectedly undefined.`);
        }
        pathEdges.push(edge);
        nodeId = edge.source === nodeId ? edge.target : edge.source;
      }

      // Return the path in correct order
      return { exists: true, pathEdges: pathEdges.reverse() };
    }

    if (!visited.has(currentNodeId)) {
      visited.add(currentNodeId);

      const connectedEdges: Edge<SegmentEdgeData>[] = edges
        .filter((edge): edge is Edge<SegmentEdgeData> => {
          // Check if the edge is of type 'Segment' and is connected to the current node
          if (!isSegmentEdge(edge) || !(edge.source === currentNodeId || edge.target === currentNodeId)) {
            return false;
          }

          // If a bundle is provided, ensure the segment's bundles match the specified bundle
          if (bundle) {
            return edge.data.bundles.some(
              (b) =>
                (b.sourceDesignPartId === bundle.sourceDesignPartId &&
                  b.destinationDesignPartId === bundle.destinationDesignPartId) ||
                (b.sourceDesignPartId === bundle.destinationDesignPartId &&
                  b.destinationDesignPartId === bundle.sourceDesignPartId),
            );
          }

          // If no bundle is provided, allow the segment to be considered
          return true;
        })
        // Filter out visited nodes
        .filter((edge) => !visited.has(edge.source === currentNodeId ? edge.target : edge.source));

      connectedEdges.forEach((edge) => {
        const nextNodeId = edge.source === currentNodeId ? edge.target : edge.source;
        const nextNode = nodes.find((node) => node.id === nextNodeId);

        if (nextNode && (isValidNode(nextNode) || nextNodeId === targetId)) {
          // Add the next node to the stack
          stack.push(nextNodeId);
          // Set the predecessor edge for this node
          predecessorMap[nextNodeId] = edge;
        }
      });
    }
  }

  // If we're here, then no path was found
  return { exists: false, pathEdges: [] };
};

/**
 * Removes all segment edges that are connected to a specified node.
 * This function modifies the `edges` array in place.
 * @param edges - The array of edges to modify.
 * @param nodeId - The ID of the node to remove connected segment edges.
 * @returns The modified edges array with the connected segment edges removed.
 */
export const removeConnectedSegments = (edges: Edge[], nodeId: UUID) => {
  // Iterate in reverse to void skipping elements when removing
  for (let i = edges.length - 1; i >= 0; i--) {
    const edge = edges[i];
    if (isSegmentEdge(edge) && (edge.source === nodeId || edge.target === nodeId)) {
      edges.splice(i, 1);
    }
  }
};

/**
 * Removes all measurement edges that are connected to a specified node.
 * This function modifies the `edges` array in place.
 * @param edges - The array of edges to modify.
 * @param nodeId - The ID of the node to remove connected measurement edges.
 * @returns The modified edges array with the connected measurement edges removed.
 */
export const removeConnectedMeasurements = (edges: Edge[], nodeId: UUID) => {
  // Iterate in reverse to void skipping elements when removing
  for (let i = edges.length - 1; i >= 0; i--) {
    const edge = edges[i];
    if (isMeasurementEdge(edge) && (edge.source === nodeId || edge.target === nodeId)) {
      edges.splice(i, 1);
    }
  }
};

/**
 * Determines if a measurement edge should be created between two nodes.
 * @param nodes
 * @param edges
 * @param node1
 * @param node2
 */
export const shouldCreateMeasurementEdge = (
  nodes: Node[],
  edges: Edge[],
  node1: Node | UUID,
  node2: Node | UUID,
): boolean => {
  // Helper function to get the Node object from nodes array or use it directly if it's already a Node
  const getNode = (nodeOrId: Node | UUID): Node | undefined =>
    typeof nodeOrId === 'string' ? nodes.find((node) => node.id === nodeOrId) : nodeOrId;

  // Resolve node1 and node2 to their Node objects
  const resolvedNode1 = getNode(node1);
  const resolvedNode2 = getNode(node2);

  // If either node is not found, return false
  if (!resolvedNode1 || !resolvedNode2) {
    return false;
  }

  // Check if both nodes are measurement nodes
  if (!isMeasurableNode(resolvedNode1) || !isMeasurableNode(resolvedNode2)) {
    return false;
  }

  // Check if the nodes are directly connected by a non-measurement edge
  const directlyConnected = isDirectlyConnected(resolvedNode1.id, resolvedNode2.id, edges);

  // Check if the nodes are connected through a layout point
  const { exists: connectedThroughLayoutPoint } = findPath(
    nodes,
    edges,
    resolvedNode1.id,
    resolvedNode2.id,
    isLayoutPointNode,
  );

  return directlyConnected || connectedThroughLayoutPoint;
};

/**
 * Sum the lengths of conductors in each bundle of all segments.
 * @param nodes
 * @param edges
 * @returns A record of conductor IDs and their summed lengths.
 */
export const sumConductorLengths = (nodes: Node[], edges: Edge[]): Record<string, number> => {
  // Track the sum of conductor lengths for each conductor ID
  const conductorSums: Record<string, number> = {};

  edges.forEach((edge) => {
    if (isMeasurementEdge(edge)) {
      // Find path edges for the measurement edge
      const pathEdges = findPath(nodes, edges, edge.source, edge.target, (node) => isLayoutNode(node)).pathEdges;
      // All pathEdges should contain the same bundles, so we can just use the first one
      if (pathEdges?.length > 0) {
        const firstEdge = pathEdges[0];
        firstEdge.data?.bundles.forEach((bundle) => {
          bundle.connections.forEach((connection) => {
            const conductorId = connection.conductorId;
            if (conductorId) {
              conductorSums[conductorId] = (conductorSums[conductorId] || 0) + Number(edge.data.measurement);
            }
          });
        });
      }
    }
  });

  return conductorSums;
};

/**
 * Removes nodes and connected edges from nodes and edges, and returns new nodes and edges
 * @param graph
 * @param nodesToRemove
 * @returns the graph with nodes from nodesToRemove and related edges removed
 *  */
export const removeNodesFromGraph = (graph: Graph, nodesToRemove: Node[]) => {
  if (nodesToRemove.length === 0) {
    return graph;
  }

  const { nodes, edges } = graph;
  const nodeIdsToRemoveSet = new Set(map(nodesToRemove, 'id'));
  const updatedNodes = reject(nodes, (node) => nodeIdsToRemoveSet.has(node.id));
  const updatedEdges = reject(
    edges,
    (edge) => nodeIdsToRemoveSet.has(edge.source) || nodeIdsToRemoveSet.has(edge.target),
  );

  return { nodes: updatedNodes, edges: updatedEdges };
};
