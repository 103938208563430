import { AlternateDesignPart, Design, DesignPart, UUID } from '@senrasystems/senra-ui';
import { useDesignQuery } from '../api/queries.ts';
import { useDesignId } from './useDesignId.tsx';
import { useMemo } from 'react';

// Define the return type
interface UseDesignPartsResult {
  designParts: DesignPart[];
  alternateDesignParts: AlternateDesignPart[];
  designPartsMap: Map<UUID, DesignPart>;
  alternateDesignPartsMap: Map<UUID, AlternateDesignPart>;
  getDesignPartById: (id: UUID) => DesignPart | undefined;
  getAlternateDesignPartById: (id: UUID) => AlternateDesignPart | undefined;
  isLoading: boolean;
  error: Error | null;
}

// Initialize designParts and alternateDesignParts
const emptyDesignParts = [] as DesignPart[];
const emptyAlternateDesignParts = [] as AlternateDesignPart[];

// Define the select function
const selectDesignParts = (design: Design | undefined) => ({
  designParts: design?.designParts || emptyDesignParts,
  alternateDesignParts: design?.alternateDesignParts || emptyAlternateDesignParts,
});

/**
 * Custom hook to fetch designParts, alternateDesignParts, lookup maps, and utility functions. This hook uses the
 * select function of useDesignQuery to only subscribe to designParts and alternateDesignParts.
 */
export const useDesignParts = (): UseDesignPartsResult => {
  const designId = useDesignId();

  // Fetch designParts and alternateDesignParts
  const { data, isLoading, error } = useDesignQuery(designId, selectDesignParts);
  const designParts = data?.designParts || emptyDesignParts;
  const alternateDesignParts = data?.alternateDesignParts || emptyAlternateDesignParts;

  // Create a map of designParts for easy lookup
  const designPartsMap = useMemo(() => {
    const map = new Map<UUID, DesignPart>();
    designParts.forEach((part) => {
      map.set(part.id, part);
    });
    return map;
  }, [designParts]);

  // Create a map of alternateDesignParts for easy lookup
  const alternateDesignPartsMap = useMemo(() => {
    const map = new Map<UUID, AlternateDesignPart>();
    alternateDesignParts.forEach((alternatePart) => {
      map.set(alternatePart.id, alternatePart);
    });
    return map;
  }, [alternateDesignParts]);

  // Utility function to get a design part by ID
  const getDesignPartById = (id: UUID): DesignPart | undefined => designPartsMap.get(id);

  // Utility function to get an alternate design part by ID
  const getAlternateDesignPartById = (id: UUID): AlternateDesignPart | undefined => alternateDesignPartsMap.get(id);

  return {
    designParts,
    alternateDesignParts,
    designPartsMap,
    alternateDesignPartsMap,
    getDesignPartById,
    getAlternateDesignPartById,
    isLoading,
    error,
  };
};
