import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, HStack, Icon, Text } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ConnectorData, DesignPart, PartGroup, PartType } from '@senrasystems/senra-ui';
import PartListItem from './PartListItem.tsx';
import Title from '@web/components/Title.tsx';
import AddIconButton from '../../../components/AddIconButton.tsx';
import { MouseEvent } from 'react';
import { useDesign } from '../../../hooks/useDesign.tsx';
import DiffIndicator from './DiffIndicator.tsx';

interface Props {
  connectors: DesignPart[];
  ungroupedParts: DesignPart[];
  onAddPart?: (types: PartType[], connector?: DesignPart) => void;
  onReplacePart?: (part: DesignPart) => void;
  designLengthUnit?: string;
  hasFocus?: boolean;
}

const ConnectorList = ({ connectors, ungroupedParts, onAddPart, onReplacePart, designLengthUnit, hasFocus }: Props) => {
  const { isSelectedDesignPart, designId, lockedAt } = useDesign();

  const getConnectorAccessoryGroups = (connector: DesignPart) => {
    return [
      {
        name: 'Backshell',
        types: [PartType.BACKSHELL],
        parts: connector.backshell ? [connector.backshell] : [],
      },
      {
        name: 'Contacts',
        types: [PartType.CONTACT],
        parts: connector.contacts || [],
      },
    ];
  };

  const getAccessoryGroups = () => {
    return [
      {
        name: 'Backshell',
        types: [PartType.BACKSHELL],
        parts: ungroupedParts.filter((part) => part.partData.type === PartType.BACKSHELL),
      },
      {
        name: 'Contacts',
        types: [PartType.CONTACT],
        parts: ungroupedParts.filter((part) => part.partData.type === PartType.CONTACT) || [],
      },
    ];
  };

  const handleAddPart = (event: MouseEvent<HTMLButtonElement>, types: PartType[], connector?: DesignPart) => {
    event.stopPropagation();
    if (onAddPart) {
      onAddPart(types, connector);
    }
  };

  const handleReplacePart = (part: DesignPart) => {
    if (onReplacePart) {
      onReplacePart(part);
    }
  };

  const renderAccessoryHeading = (group: PartGroup, connector?: DesignPart) => {
    if (
      connector
        ? group.name === 'Backshell'
          ? (connector.partData as ConnectorData).acceptsBackshell
          : (connector.partData as ConnectorData).acceptsContacts
        : true
    ) {
      const showAddButton = hasFocus && onAddPart && !(group.name === 'Backshell' && connector?.backshell);
      return (
        <Title
          as="h4"
          title={
            <HStack verticalAlign="center">
              <Text>{group.name}</Text>
              {!lockedAt && (
                <AddIconButton
                  aria-label={`Add ${group.types[0]}`}
                  variant="secondary"
                  onClick={(event) => {
                    handleAddPart(event, [group.types[0]], connector);
                  }}
                  opacity={showAddButton ? 1 : 0}
                  transition="opacity .3s ease-in-out"
                />
              )}
            </HStack>
          }
          size="sm"
          color={group.parts.length === 0 ? 'gray.500' : 'gray.900'}
          h={4}
          pl={12}
          py={4}
        />
      );
    } else {
      return (
        <Title
          as="h4"
          title={<Text>{group.name} not supported</Text>}
          size="sm"
          color={group.parts.length === 0 ? 'gray.500' : 'gray.900'}
          h={4}
          pl={12}
          py={4}
        />
      );
    }
  };

  return (
    <Accordion allowMultiple>
      {connectors.map((connector) => (
        <AccordionItem key={connector.id} border="none">
          {({ isExpanded }) => (
            <Box>
              <AccordionButton
                as={Box}
                bg={isSelectedDesignPart(connector) ? 'blue.100' : 'transparent'}
                _hover={isSelectedDesignPart(connector) ? { bg: 'blue.100' } : { bg: 'gray.100' }}
                pl={4}
                pr={0}
                py={0}
                gap={1}
              >
                <DiffIndicator part={connector} designId={designId} />
                <Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} />
                <PartListItem
                  part={connector}
                  designLengthUnit={designLengthUnit || ''}
                  onReplacePart={() => {
                    handleReplacePart(connector);
                  }}
                  hasFocus={hasFocus}
                  w="full"
                  hideDiffIndicator
                />
              </AccordionButton>
              <AccordionPanel px={0} py={0} bg="gray.50">
                {getConnectorAccessoryGroups(connector).map((group) => (
                  <Box key={group.name} pb={2}>
                    {renderAccessoryHeading(group, connector)}
                    {group.parts.map((part) => (
                      <PartListItem
                        key={part.id}
                        part={part}
                        onReplacePart={() => {
                          handleReplacePart(part);
                        }}
                        designLengthUnit={designLengthUnit || ''}
                        hasFocus={hasFocus}
                        pl={12}
                      />
                    ))}
                  </Box>
                ))}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
      ))}
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <Box>
            <AccordionButton as={Box} h="2em" pl={9} pr={0} py={0} gap={5}>
              <Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} />
              <Text>Ungrouped Accessories</Text>
            </AccordionButton>
            <AccordionPanel px={0} py={0} bg="gray.50">
              {getAccessoryGroups().map((group) => (
                <Box key={group.name} pb={2}>
                  {renderAccessoryHeading(group)}
                  {group.parts.map((part) => (
                    <PartListItem
                      key={part.id}
                      part={part}
                      designLengthUnit={designLengthUnit}
                      onReplacePart={() => {
                        handleReplacePart(part);
                      }}
                      pl={16}
                    />
                  ))}
                </Box>
              ))}
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default ConnectorList;
