import { S3Download } from './lib';

export const usdCurrencyFormatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const fetchDocument = async (document: S3Download) => {
  const data = await fetch(document.url);
  return await data.blob();
};

export const getDocumentObjectURL = async (document: S3Download) => {
  const blob = await fetchDocument(document);
  return URL.createObjectURL(blob);
};

export const valueToLabel = (value: string, options: { label: string; value: string }[]) => {
  return options.find((option) => option.value === value)?.label;
};
