import { isValidElement, ReactNode } from 'react';
import { Text, Box } from '@chakra-ui/react';

interface EditableFormTextProps {
  editing: boolean;
  value?: string | { label: string; value: string }[] | null;
  label: string | null;
  children: ReactNode;
}

/**
 * EditableFormText is a wrapper component handles displaying either text or an input field in place.
 */
const EditableFormText = ({ editing, value, label, children }: EditableFormTextProps) => {
  let derivedValue = '';

  if (value) {
    if (Array.isArray(value)) {
      derivedValue = value.map((item) => item.label).join(', ');
    } else {
      derivedValue = value;
    }
  }
  if (!value && isValidElement(children)) {
    const childElement = children as React.ReactElement;
    const childValue = childElement.props.value;

    if (Array.isArray(childValue)) {
      if (childValue.length > 0 && typeof childValue[0] === 'object' && 'label' in childValue[0]) {
        derivedValue = childValue.map((item: { label: string }) => item.label).join(', ');
      } else {
        derivedValue = childValue.join(', ');
      }
    } else if (typeof childValue === 'string') {
      derivedValue = childValue;
    }
  }

  return editing ? (
    <>{children}</>
  ) : (
    <Box>
      <Text color="gray.500" fontSize="md">
        {label ? label + ':' : null}
      </Text>
      <Text fontSize="lg">{derivedValue || null}</Text>
    </Box>
  );
};

export default EditableFormText;
