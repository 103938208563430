import { Operations } from '../Operations.ts';
import { Bundle, Graph, isDesignPartNode, isSegmentEdge } from '../../types.ts';
import { ResolvedConnection } from '@senrasystems/senra-ui';
import { getConnectedSegments } from '../../utils/graph.ts';
import { isEmpty, reject } from 'lodash';
import { Edge } from '@xyflow/react';

// Operation to validate the layout
export type ValidateLayoutOperation = {
  type: 'ValidateLayout';
  params: {
    connections: ResolvedConnection[];
  };
};

/**
 * Validates the layout by removing any edges with no connections, and designPart nodes with no Segment edges.
 */
export class ValidateLayout implements Operations<ValidateLayoutOperation> {
  // Execute the operation
  execute(graph: Graph, operation: ValidateLayoutOperation): Graph {
    const { nodes, edges } = graph;
    const { connections } = operation.params;

    // Map connections to their IDs for faster lookup
    const connectionById = new Map(connections.map((connection) => [connection.id, connection]));

    // Remove old connections
    const updatedEdges = edges.reduce((prevEdges, edge) => {
      if (isSegmentEdge(edge)) {
        // Update bundles by filtering out those with no connections
        const updatedBundles = edge.data.bundles.reduce((bundles, bundle) => {
          const filteredConnections = bundle.connections.filter((connection) => connectionById.get(connection.id));

          // Keep the bundles with connections
          if (filteredConnections.length > 0) {
            bundles.push({ ...bundle, connections: filteredConnections });
          }

          return bundles;
        }, [] as Bundle[]);

        // Keep the edges with bundles
        if (updatedBundles.length > 0) {
          const updatedData = { ...edge.data, bundles: updatedBundles };
          prevEdges.push({ ...edge, data: updatedData });
        }
      } else {
        prevEdges.push(edge);
      }

      return prevEdges;
    }, [] as Edge[]);

    // Remove designPart nodes with no Segment edges
    const updatedNodes = reject(
      nodes,
      (node) => isDesignPartNode(node) && isEmpty(getConnectedSegments(updatedEdges, node.id)),
    );

    return {
      nodes: updatedNodes,
      edges: updatedEdges,
    };
  }
}
