import { Box, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { compact } from 'lodash';
import Title from '@web/components/Title.tsx';
import { ReactNode } from 'react';
import AddIconButton from '../../../components/AddIconButton.tsx';
import { useDesignOverview } from '../../../hooks/useDesignOverview.tsx';
import { PartRevisionLabel } from '../../../components/PartRevisionLabel.tsx';

interface Props {
  onAddPart: () => void;
  windowIcon?: ReactNode;
  hasFocus: boolean;
}

/**
 * AssemblyNavigatorTitle component displays the title of the Assembly Navigator window.
 * @param onAddPart
 * @param windowIcon
 * @param hasFocus
 * @constructor
 */
const AssemblyNavigatorTitle = ({ onAddPart, windowIcon, hasFocus }: Props) => {
  const { partNumber, partRevision, description, lockedAt, tenant } = useDesignOverview();
  const subtitleFields = compact([tenant.name, description]).join(' / ');

  const handleAddButtonClick = () => {
    onAddPart();
  };

  return (
    <Flex flexDirection="column" p={4}>
      <Title
        title={
          <HStack>
            <PartRevisionLabel partNumber={partNumber} partRevision={partRevision} badgeProps={{ p: 1 }} />
            <Box>{windowIcon}</Box>
          </HStack>
        }
        as="h2"
        size="md"
      >
        {!lockedAt && (
          <AddIconButton
            aria-label="Add Part"
            variant="primary"
            color={hasFocus ? 'blue.500' : 'gray.500'}
            onClick={handleAddButtonClick}
            opacity={hasFocus ? 1 : 0}
            transition="opacity .3s ease-in-out"
          />
        )}
      </Title>
      <Tooltip label={subtitleFields}>
        <Text noOfLines={1}>{subtitleFields}</Text>
      </Tooltip>
    </Flex>
  );
};

export default AssemblyNavigatorTitle;
