import { useDesignParts } from './useDesignParts.tsx';
import { useMemo } from 'react';
import { DesignPart, isOverwrapDesignPart } from '@senrasystems/senra-ui';

/**
 * Custom hook to filter designParts to only include overwraps.
 */
export const useOverwraps = () => {
  const { designParts } = useDesignParts();

  const overwraps = useMemo(() => designParts.filter((part) => isOverwrapDesignPart(part)), [designParts]);

  const overwrapsByPartNumber = useMemo(() => {
    const map = new Map<string, DesignPart>();
    overwraps.forEach((part) => {
      if (isOverwrapDesignPart(part)) {
        map.set(part.partData.partNumber, part);
      }
    });
    return map;
  }, [overwraps]);

  return { overwraps, overwrapsByPartNumber };
};
