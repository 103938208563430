import { Box } from '@chakra-ui/react';
import ManagedWindow from '../../components/ManagedWindow.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import PartList from './components/PartList.tsx';
import { useState } from 'react';
import OverwrapList from './components/OverwrapList.tsx';
import AssemblyNavigatorTitle from './components/AssemblyNavigatorTitle.tsx';
import DrawingSectionTitle from './components/DrawingSectionTitle.tsx';
import { usePartSearchActions } from './hooks/usePartSearchActions.tsx';

/**
 * AssemblyNavigator component displays the parts used to build the Design.
 * @constructor
 */
const AssemblyNavigator = () => {
  const { isOpen, handleAddGeneralPart, handleAddSpecificPart, handleReplacePart } = usePartSearchActions();
  const [expandAll, setExpandAll] = useState(true);

  return (
    <ManagedWindow title="Assembly Navigator" routeName={RouteNames.DESIGNS.DESIGN_PARTS} showTitle={false}>
      {({ hasFocus, windowIcon }) => (
        <Box>
          <AssemblyNavigatorTitle
            onAddPart={() => {
              setExpandAll(true);
              handleAddGeneralPart();
            }}
            windowIcon={windowIcon}
            hasFocus={hasFocus && !isOpen}
          />
          <PartList
            h="60vh"
            onAddPart={handleAddSpecificPart}
            onReplacePart={handleReplacePart}
            expandAllItems={expandAll}
            hasFocus={hasFocus && !isOpen}
            mb={4}
          />
          <DrawingSectionTitle />
          <OverwrapList />
        </Box>
      )}
    </ManagedWindow>
  );
};

export default AssemblyNavigator;
