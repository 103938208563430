import { Edge, Node } from '@xyflow/react';
import { Connection, UUID } from '@senrasystems/senra-ui';
import { BreakoutPointNode, BreakoutPointNodeData } from './components/nodes/BreakoutPointNode/BreakoutPointNode.tsx';
import { LayoutPointNode, LayoutPointNodeData } from './components/nodes/LayoutPointNode/LayoutPointNode.tsx';
import { ConnectorNode, ConnectorNodeData } from './components/nodes/ConnectorNode/ConnectorNode.tsx';
import { PassiveNode, PassiveNodeData } from './components/nodes/PassiveNode/PassiveNode.tsx';
import { SpliceNode, SpliceNodeData } from './components/nodes/SpliceNode/SpliceNode.tsx';
import SegmentEdge, { SegmentEdgeData } from './components/edges/SegmentEdge/SegmentEdge.tsx';
import MeasurementEdge, { MeasurementEdgeData } from './components/edges/MeasurementEdge/MeasurementEdge.tsx';
import NoteGroupNode, { NoteGroupNodeData } from './components/nodes/NoteGroupNode/NoteGroupNode.tsx';
import { PartNoteEdge } from './components/edges/NoteEdge/PartNoteEdge.tsx';
import { SegmentNoteEdge, SegmentNoteEdgeData } from './components/edges/NoteEdge/SegmentNoteEdge.tsx';
import { PigtailNode, PigtailNodeData } from './components/nodes/PigtailNode/PigtailNode.tsx';

// =======================
// Graph Definitions
// =======================

// Graph (nodes, and edges)
export interface Graph {
  nodes: Node[];
  edges: Edge[];
}

export const emptyGraph: Graph = {
  nodes: [],
  edges: [],
};

// Define the graph element types
export type GraphElement = Node | Edge | null;

// =======================
// Node Type Definitions
// =======================

// Define an empty array of nodes
export const emptyNodes: Node[] = [];

// Define the node types
export enum NodeType {
  BreakoutPoint = 'BreakoutPoint',
  Connector = 'Connector',
  LayoutPoint = 'LayoutPoint',
  NoteGroup = 'NoteGroup',
  Passive = 'Passive',
  Pigtail = 'Pigtail',
  Splice = 'Splice',
}

// These are the types of nodes that are control points
export type ControlPointNodeType = NodeType.BreakoutPoint | NodeType.LayoutPoint;

// These are the types of nodes that are design parts
export type DesignPartNodeType = NodeType.Connector | NodeType.Passive | NodeType.Pigtail | NodeType.Splice;

// Define the node data types
export type CustomNodeData =
  | BreakoutPointNodeData
  | ConnectorNodeData
  | LayoutPointNodeData
  | NoteGroupNodeData
  | PassiveNodeData
  | PigtailNodeData
  | SpliceNodeData;

// A utility type to map NodeType to the corresponding CustomNodeData type
export type CustomNodeDataMap = {
  [NodeType.BreakoutPoint]: BreakoutPointNodeData;
  [NodeType.Connector]: ConnectorNodeData;
  [NodeType.LayoutPoint]: LayoutPointNodeData;
  [NodeType.NoteGroup]: NoteGroupNodeData;
  [NodeType.Passive]: PassiveNodeData;
  [NodeType.Pigtail]: PigtailNodeData;
  [NodeType.Splice]: SpliceNodeData;
};

// Register node types with specific component associations
export const nodeTypes = {
  BreakoutPoint: BreakoutPointNode,
  Connector: ConnectorNode,
  LayoutPoint: LayoutPointNode,
  NoteGroup: NoteGroupNode,
  Passive: PassiveNode,
  Pigtail: PigtailNode,
  Splice: SpliceNode,
};

// Define the node type guards
export const isBreakoutPointNode = (node: Node): node is Node<BreakoutPointNodeData> => {
  return node.type === NodeType.BreakoutPoint;
};

export const isConnectorNode = (node: Node): node is Node<ConnectorNodeData> => {
  return node.type === NodeType.Connector;
};

export const isLayoutPointNode = (node: Node): node is Node<LayoutPointNodeData> => {
  return node.type === NodeType.LayoutPoint;
};

export const isNoteGroupNode = (node: Node): node is Node<NoteGroupNodeData> => {
  return node.type === NodeType.NoteGroup;
};

export const isPassiveNode = (node: Node): node is Node<PassiveNodeData> => {
  return node.type === NodeType.Passive;
};

export const isPigtailNode = (node: Node): node is Node<PigtailNodeData> => {
  return node.type === NodeType.Pigtail;
};

export const isSpliceNode = (node: Node): node is Node<SpliceNodeData> => {
  return node.type === NodeType.Splice;
};

// Type guard to determine if a node is a design part node
export const isDesignPartNode = (
  node: Node,
): node is Node<ConnectorNodeData> | Node<PassiveNodeData> | Node<SpliceNodeData> => {
  return isConnectorNode(node) || isPassiveNode(node) || isPigtailNode(node) || isSpliceNode(node);
};

// Type guard to determine if a node is a control point node
export const isControlPointNode = (node: Node): node is Node<BreakoutPointNodeData> | Node<LayoutPointNodeData> => {
  return node.type === NodeType.BreakoutPoint || node.type === NodeType.LayoutPoint;
};

// Type guard to determine if a node is a measurement node
export const isMeasurableNode = (
  node: Node,
): node is Node<BreakoutPointNodeData | ConnectorNodeData | PassiveNodeData | PigtailNodeData | SpliceNodeData> => {
  return node.type === NodeType.BreakoutPoint || isDesignPartNode(node);
};

// Type guard to determine if a node is a layout node
export const isLayoutNode = (
  node: Node,
): node is
  | Node<ConnectorNodeData>
  | Node<PassiveNodeData>
  | Node<PigtailNodeData>
  | Node<SpliceNodeData>
  | Node<BreakoutPointNodeData>
  | Node<LayoutPointNodeData> => {
  return isDesignPartNode(node) || isControlPointNode(node);
};

// =======================
// Edge Type Definitions
// =======================

// Define an empty array of edges
export const emptyEdges: Edge[] = [];

// Define the edge types
export enum EdgeType {
  Segment = 'Segment',
  Measurement = 'Measurement',
  PartNote = 'PartNote',
  SegmentNote = 'SegmentNote',
}

// Define the edge data types
export type CustomEdgeData = MeasurementEdgeData | SegmentEdgeData | SegmentNoteEdgeData;

// A utility type to map EdgeType to the corresponding CustomEdgeData type
export type CustomEdgeDataMap = {
  [EdgeType.Measurement]: MeasurementEdgeData;
  [EdgeType.Segment]: SegmentEdgeData;
  [EdgeType.PartNote]: Record<string, unknown>;
  [EdgeType.SegmentNote]: SegmentNoteEdgeData;
};

// Register edge types with specific component associations
export const edgeTypes = {
  Segment: SegmentEdge,
  Measurement: MeasurementEdge,
  PartNote: PartNoteEdge,
  SegmentNote: SegmentNoteEdge,
};

// Define the edge type guards
export const isSegmentEdge = (edge: Edge): edge is Edge<SegmentEdgeData> & { data: SegmentEdgeData } => {
  return edge.type === EdgeType.Segment && edge.data !== undefined;
};

export const isMeasurementEdge = (edge: Edge): edge is Edge<MeasurementEdgeData> & { data: MeasurementEdgeData } => {
  return edge.type === EdgeType.Measurement && edge.data !== undefined;
};

export const isPartNoteEdge = (edge: Edge): edge is Edge => {
  return edge.type === EdgeType.PartNote;
};

export const isSegmentNoteEdge = (edge: Edge): edge is Edge<SegmentNoteEdgeData> => {
  return edge.type === EdgeType.SegmentNote && edge.data !== undefined;
};

// =======================
// Other Definitions
// =======================

// A collection of overwraps, these are Design Part IDs. The order of the overwraps is important. The first overwrap is
// the innermost overwrap.
export type Overwraps = UUID[];

// A collection of bundles
export type Bundles = Bundle[];

// A bundle is a collection of connections between two design parts
export type Bundle = {
  id: string;
  sourceDesignPartId: string;
  destinationDesignPartId: string;
  connections: Connection[];
};
