import { useOverwraps } from '../../../../../hooks/useOverwraps.tsx';
import { useId } from 'react';
import { isOverwrapDesignPart } from '@senrasystems/senra-ui';
import { getPatternType } from '../../../../../../utils/patterns.ts';
import { patternComponentMap } from '../../../../../components/DynamicPatternIcon.tsx';

const emptyPatterns = { pattern: null, selectedPattern: null };

export const useOverwrapPatterns = (overwraps: string[]) => {
  const { overwrapsByPartNumber } = useOverwraps();
  const patternId = useId();
  const selectedPatternId = useId();

  if (overwraps.length > 0) {
    const outermostOverwrapPartNumber = overwraps[overwraps.length - 1];
    const overwrap = overwrapsByPartNumber.get(outermostOverwrapPartNumber);

    if (overwrap && isOverwrapDesignPart(overwrap)) {
      const patternType = getPatternType(overwrap.partData.subtype);
      const patternColor = overwrap.partData.color;
      if (patternType) {
        const PatternComponent = patternComponentMap[patternType]; // Corrected to use `patternType`

        if (patternType && PatternComponent && patternColor) {
          return {
            pattern: { patternId, patternComponent: <PatternComponent id={patternId} color={patternColor} /> },
            selectedPattern: {
              patternId: selectedPatternId,
              patternComponent: <PatternComponent id={selectedPatternId} color={patternColor} />,
            },
          };
        }
      }
    }
  }

  return emptyPatterns;
};
