import { useController, useFormContext } from 'react-hook-form';
import { Flex, Box, FormControl, FormLabel, Switch, HStack } from '@chakra-ui/react';
import TextInput from '../../../../components/form/TextInput.tsx';
import { Wire } from '@senrasystems/senra-ui';
import { milSpecColors } from '@web/common/colors.ts';
import { TSelectOption } from '@web/common/lib/common-types/dropdown.tsx';
import { Select, MultiValue } from 'chakra-react-select';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing: boolean;
}

const WireFields = ({ editing }: Props) => {
  const { control, getValues, register, setValue } = useFormContext<Wire>();
  const { field: colors } = useController({ name: 'colors', control });
  const { field: cableOnlyWire } = useController({ name: 'cableOnlyWire', control });
  const colorOptions: TSelectOption<string>[] = Array.from(Object.keys(milSpecColors)).map((v) => ({
    label: v,
    value: v,
    id: v,
  }));

  const selectedColorOptions = colors?.value
    ? colors.value.map((current) => {
        return colorOptions.find((color) => color.value === current) as TSelectOption<string>;
      })
    : [];

  const handleOnChange = (newOption: MultiValue<TSelectOption<string>>) => {
    if (newOption) {
      colors.onChange(newOption);
      setValue('colors', [...newOption.map((opt) => opt.value)]);
    }
  };

  return (
    <Flex justifyContent="space-between" gap={10}>
      <HStack flex={1}>
        <Box flex={1}>
          <EditableFormText editing={editing} label="AWG" value={getValues('gauge')}>
            <FormControl>
              <FormLabel id="awgLabel">AWG</FormLabel>
              <TextInput
                aria-labelledby="awgLabel"
                formControlProps={{ isDisabled: !editing }}
                placeholder="Gauge"
                type="number"
                {...register('gauge')}
              />
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex={1}>
          <EditableFormText
            editing={editing}
            label="Colors"
            value={selectedColorOptions.map((color) => color.value).join(', ')}
          >
            <FormControl>
              <FormLabel>Colors</FormLabel>
              <Select
                isMulti
                value={selectedColorOptions}
                options={colorOptions}
                onChange={handleOnChange}
                isDisabled={!editing}
                menuPortalTarget={document.getElementById('portal')}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </FormControl>
          </EditableFormText>
        </Box>
      </HStack>
      <Box flex={1}>
        <EditableFormText editing={editing} label="Is a cable only wire" value={cableOnlyWire.value ? 'Yes' : 'No'}>
          <FormControl>
            <FormLabel>Is a cable only wire</FormLabel>
            <Switch
              isDisabled={!editing}
              isChecked={cableOnlyWire.value}
              onChange={(e) => cableOnlyWire.onChange(e.target.checked)}
            />
          </FormControl>
        </EditableFormText>
      </Box>
    </Flex>
  );
};

export default WireFields;
