import { useCallback, useMemo } from 'react';
import { Box, Button, HStack, Spacer, StackProps, Text } from '@chakra-ui/react';
import { acceptsContacts, Connection, ConnectionPoint, UUID } from '@senrasystems/senra-ui';
import { useStateWithDirtyFlag } from '@web/hooks/useStateWithDirtyFlag.ts';
import { useConnections } from '../../../hooks/useConnections.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { useConductorOptions } from '../hooks/useConductorOptions.tsx';
import { useDestinationOptions } from '../hooks/useDestinationOptions.tsx';
import { useSourceContactOptions } from '../hooks/useSourceContactOptions.tsx';
import { useDesignPartContactOptions } from '../hooks/useDesignPartContactOptions.tsx';
import { BiPlus } from 'react-icons/bi';
import { ConnectionItemDropdown } from './ConnectionItemDropdown.tsx';
import { ConnectionItemInput } from './ConnectionItemInput.tsx';
import { useSaveOnBlurConnectionItem } from '../hooks/useSaveOnBlurConnectionItem.ts';
import { FieldKey, Fields } from '../wiringListTypes.ts';
import { useHandleConductorIdChange } from '../hooks/useHandleConductorChange.ts';

// Initial state for connection fields
const defaultInitialState: Fields = {
  destinationId: '',
  conductorId: '',
  signalName: '',
  sourceContactId: '',
  destinationContactId: '',
};

const getInitialState = (connection: Connection | undefined) => {
  if (connection) {
    const { destinationId, conductorId, signalName, sourceContactId, destinationContactId } = connection;

    return {
      destinationId: destinationId ?? '',
      conductorId: conductorId ?? '',
      signalName: signalName ?? '',
      sourceContactId: sourceContactId ?? '',
      destinationContactId: destinationContactId ?? '',
    };
  }
  return defaultInitialState;
};

// ConnectionItem component props
interface Props extends StackProps {
  id: UUID;
  connectionPoint: ConnectionPoint;
  connection?: Connection;
  isEditing: boolean;
  isAnyConnectionEditing: boolean;
  stopEditing: () => void;
  startEditing?: (id: UUID) => void;
  onAdd?: (id: UUID) => void;
}

/**
 * ConnectionItem component displays a single row of the WiringList feature. A connection is from the perspective of
 * the selected design part's connection point.
 * @param id
 * @param connectionPoint
 * @param existingConnection
 * @param isEditing
 * @param isAnyConnectionEditing
 * @param startEditing
 * @param stopEditing
 * @param onAdd
 * @param rest
 * @constructor
 */
const ConnectionItem = ({
  id,
  connectionPoint,
  connection,
  isEditing,
  isAnyConnectionEditing,
  startEditing,
  stopEditing,
  onAdd,
  ...rest
}: Props) => {
  // selectedDesignPart is used to get contacts, and is assumed to be set when this component renders
  const { designId, lockedAt, selectedDesignPart, selectedConnection } = useDesign();

  // Hook to manage connections
  const { connectionPointsById, createConnection, updateConnection, deleteConnection } = useConnections();

  // State to manage connection data
  const initialState = useMemo(() => getInitialState(connection), [connection]);
  const { state, isDirty, setStateWithDirtyFlag, resetState } = useStateWithDirtyFlag(initialState);

  // Options for dropdowns
  const { options: destinationOptions } = useDestinationOptions(connectionPoint, connection?.destinationId ?? '');
  const { options: conductorOptions } = useConductorOptions(connection?.conductorId ?? '');
  const { options: sourceContactOptions } = useSourceContactOptions();
  const { options: destinationContactOptions } = useDesignPartContactOptions(state.destinationId);

  // Change handlers for fields
  const handleChange = (field: FieldKey) => (value: string | boolean) =>
    setStateWithDirtyFlag({ ...state, [field]: value });

  const handleConductorIdChange = useHandleConductorIdChange(
    setStateWithDirtyFlag,
    connectionPoint,
    connectionPointsById,
    sourceContactOptions,
    destinationContactOptions,
  );

  // Edit the connection
  const handleEdit = () => {
    if (!lockedAt && startEditing) {
      resetState();
      startEditing(id);
    }
  };

  // Clear the connection
  const handleClear = async () => {
    if (connection) {
      await deleteConnection.mutateAsync({ designId: designId, connectionId: connection.id });
    }

    resetState();
    stopEditing();
  };

  // Cancel the edit
  const handleCancel = () => {
    resetState();
    stopEditing();
  };

  // Save the connection
  const saveConnection = useCallback(
    async (updatedState: Fields) => {
      // Build the connection data
      const connectionData = {
        sourceId: connectionPoint.id,
        destinationId: updatedState.destinationId,
        conductorId: updatedState.conductorId,
        signalName: updatedState.signalName,
        sourceContactId: updatedState.sourceContactId,
        destinationContactId: updatedState.destinationContactId,
      };

      if (!connection) {
        // Create a new connection
        await createConnection.mutateAsync({ designId: designId, data: connectionData });
      }

      if (connection) {
        // Update an existing connection
        await updateConnection.mutateAsync({ designId: designId, connectionId: connection.id, data: connectionData });
      }

      resetState();
    },
    [connection, connectionPoint.id, createConnection, designId, updateConnection, resetState],
  );

  const handleSave = useCallback(async () => {
    await saveConnection(state);
    stopEditing();
  }, [saveConnection, state, stopEditing]);

  // If it's not editing and it's dirty, save, which should reset the dirty flag
  useSaveOnBlurConnectionItem(isEditing, isDirty, saveConnection, state);

  const cancelSaveButtons = (
    <HStack>
      <Button variant="unstyled" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        variant="unstyled"
        onClick={handleSave}
        isDisabled={!state.destinationId && !state.conductorId}
        isLoading={createConnection.isPending || updateConnection.isPending}
      >
        Save
      </Button>
    </HStack>
  );

  const editClearButtons = (
    <HStack>
      <Button
        variant="unstyled"
        onClick={handleEdit}
        display={'none'}
        _groupHover={{
          display: !isEditing && !isAnyConnectionEditing ? 'block' : 'none',
        }}
      >
        Edit
      </Button>
      <Button
        variant="unstyled"
        onClick={handleClear}
        isDisabled={connection === undefined}
        isLoading={deleteConnection.isPending}
        display="none"
        _groupHover={{
          display: connection && !isEditing && !isAnyConnectionEditing ? 'block' : 'none',
        }}
      >
        Clear
      </Button>
    </HStack>
  );

  const displayState = isDirty ? state : initialState;

  return (
    <HStack
      _groupHover={{ bg: selectedConnection?.id === connection?.id ? 'transparent' : 'gray.50' }}
      onDoubleClick={handleEdit}
      {...rest}
    >
      <Box flex={1} pl={4}>
        <Text flex={1}>{connectionPoint.name}</Text>
      </Box>
      <Box flex={1}>
        <ConnectionItemDropdown
          isEditing={isEditing}
          value={displayState.destinationId}
          options={destinationOptions}
          onChange={handleChange('destinationId')}
        />
      </Box>
      <Box flex={1}>
        <ConnectionItemDropdown
          isEditing={isEditing}
          value={displayState.conductorId}
          options={conductorOptions}
          onChange={handleConductorIdChange}
        />
      </Box>
      <Box flex={1}>
        <ConnectionItemInput
          isEditing={isEditing}
          value={displayState.signalName}
          onChange={handleChange('signalName')}
        />
      </Box>
      <Box flex={1}>
        <ConnectionItemDropdown
          isEditing={!!selectedDesignPart && acceptsContacts(selectedDesignPart) && isEditing}
          value={displayState.sourceContactId}
          options={sourceContactOptions}
          onChange={handleChange('sourceContactId')}
        />
      </Box>
      <Box flex={1}>
        <ConnectionItemDropdown
          isEditing={isEditing}
          value={displayState.destinationContactId}
          options={destinationContactOptions}
          onChange={handleChange('destinationContactId')}
        />
      </Box>
      <Spacer />
      <Box display="flex" justifyContent="flex-end">
        {!lockedAt && (
          <HStack h="30px" w="100px" justifyContent="flex-end" pr={4}>
            {isEditing ? cancelSaveButtons : editClearButtons}
            <Box
              onClick={() => onAdd && onAdd(id)}
              display={'none'}
              _groupHover={{
                display: connection && !isEditing && !isAnyConnectionEditing ? 'block' : 'none',
              }}
              cursor="pointer"
            >
              <BiPlus aria-label="Add connection" fontSize="1.25em" />
            </Box>
          </HStack>
        )}
      </Box>
    </HStack>
  );
};

export default ConnectionItem;
