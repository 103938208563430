import { NavLink, useLocation } from 'react-router-dom';
import { RouteNames } from '@web/consts/routeNames.ts';
import { feedbackFormUrl } from '../../common/socials';
import SignInButton from '../auth/SignInButton';
import { AuthenticatedTemplate, EmployeeTemplate, UnauthenticatedTemplate } from '../auth/RenderTemplates';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  UnorderedList,
  useColorMode,
} from '@chakra-ui/react';
import { RiSettings5Fill } from 'react-icons/ri';
import { FaMoon, FaSun } from 'react-icons/fa';
import Logo from '../svg/Logo';

const LogoWrapper = () => (
  <NavLink to={RouteNames.ROOT}>
    <Box mx={4}>
      <Logo />
    </Box>
  </NavLink>
);

export default function TopbarNav() {
  const { colorMode, toggleColorMode } = useColorMode();
  const location = useLocation();
  const isStaging = import.meta.env.VITE_ENVIRONMENT === 'staging';
  const isLightMode = colorMode === 'light';

  return (
    <Flex
      padding="7px"
      alignItems="center"
      width="full"
      borderBottom="1px"
      borderColor={isLightMode ? 'gray.300' : 'gray.700'}
      backgroundColor={isStaging ? 'red.200' : isLightMode ? 'gray.200' : 'gray.800'}
    >
      <LogoWrapper />
      <Flex flex={1} fontSize="sm">
        <UnorderedList listStyleType="none">
          <HStack spacing={8} mt={4} mb={4}>
            <AuthenticatedTemplate>
              <li>
                <NavbarLink text="Orders" to={RouteNames.ORDERS.INDEX} />
              </li>
            </AuthenticatedTemplate>
            <EmployeeTemplate>
              <li>
                <NavbarLink text="Designs" to={RouteNames.DESIGNS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Parts Library" to={RouteNames.PARTS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Tenants" to={RouteNames.TENANTS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Users" to={RouteNames.USERS.INDEX} />
              </li>
            </EmployeeTemplate>
          </HStack>
        </UnorderedList>
      </Flex>
      <Flex fontSize="sm">
        <AuthenticatedTemplate>
          <HStack>
            {!location.pathname.startsWith(RouteNames.DESIGNS.INDEX) && (
              <Tooltip label={`Toggle ${colorMode === 'light' ? 'dark' : 'light'} mode`}>
                <IconButton aria-label="Color mode toggle" variant="ghost" onClick={toggleColorMode}>
                  {colorMode === 'light' ? <FaMoon size={16} /> : <FaSun size={16} />}
                </IconButton>
              </Tooltip>
            )}
            <Menu>
              <MenuButton aria-label="Options menu" tabIndex={0} cursor="pointer" data-cy="menu-button">
                <RiSettings5Fill size={26} />
              </MenuButton>
              <MenuList borderRadius={0}>
                <NavLink to={RouteNames.PROFILE}>
                  <MenuItem>Personal settings</MenuItem>
                </NavLink>
                <NavLink to={RouteNames.COMPANY_PROFILE}>
                  <MenuItem>Company settings</MenuItem>
                </NavLink>
                <MenuItem as="a" href={feedbackFormUrl} target="_blank" rel="noreferrer">
                  Submit feedback
                </MenuItem>
                <NavLink to={RouteNames.SIGN_OUT}>
                  <MenuItem color="red.300">Sign Out</MenuItem>
                </NavLink>
              </MenuList>
            </Menu>
          </HStack>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <li>
            <SignInButton />
          </li>
        </UnauthenticatedTemplate>
      </Flex>
    </Flex>
  );
}

const NavbarLink = ({ text, to }: { text: string; to: string; showActive?: boolean }) => {
  return (
    <NavLink
      to={to}
      style={({ isActive }) => {
        return {
          opacity: isActive ? 1 : 0.5,
          fontSize: '10pt',
        };
      }}
    >
      {text}
    </NavLink>
  );
};
