import { Box, Flex, Grid } from '@chakra-ui/react';
import { useWindowManager } from '../hooks/useWindowManager.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import { generatePath } from 'react-router-dom';
import { AssemblyNavigator } from '@web/apps/Design';
import TopbarNav from '@web/components/navigation/TopbarNav.tsx';
import { useDesignId } from '../hooks/useDesignId.tsx';
import { DesignTabs } from './DesignTabs.tsx';

const BORDER_LINE = '2px solid';
const BORDER_COLOR = 'gray.300';

/**
 * Design component displays the Assembly Navigator on the left, and main content on the right.
 * @constructor
 */
const Design = () => {
  const designId = useDesignId();
  const { isWindowOpened } = useWindowManager();

  return (
    <Flex direction="column" overflow="hidden" height="full">
      {/* Header Section */}
      <TopbarNav />
      {/* Content Section */}
      {isWindowOpened(generatePath(RouteNames.DESIGNS.DESIGN_PARTS, { designId })) ? (
        <Box flex="1" overflow="auto">
          <DesignTabs />
        </Box>
      ) : (
        <Grid templateColumns="1fr 3fr" flex="1" overflow="auto">
          <Box overflow="auto" bg="gray.50" borderRight={BORDER_LINE} borderColor={BORDER_COLOR}>
            <AssemblyNavigator />
          </Box>
          <Box overflow="hidden">
            <DesignTabs />
          </Box>
        </Grid>
      )}
    </Flex>
  );
};

export default Design;
