import { Tabs, TabList, Tab, TabPanels, TabPanel, TabProps, TabPanelProps } from '@chakra-ui/react';
import BOM from '../features/BOM';
import { LayoutWithNotes } from '../features/Layout';
import { SchematicWithWiringList } from '../features/Schematic';
import { featureFlag, useFeatureFlag } from '../../../hooks/useFeatureFlag';

const DesignTab = (props: TabProps) => (
  <Tab fontWeight="semibold" color={'gray.500'} _selected={{ color: 'gray.900' }} {...props} />
);

const DesignTabPanel = (props: TabPanelProps) => (
  <TabPanel p={0} display="flex" flexDirection="column" flex={1} {...props} />
);

export const DesignTabs = () => {
  const isSchematicEnabled = useFeatureFlag(featureFlag.Schematic);

  return (
    <Tabs variant="unstyled" height="full" display="flex" flexDirection="column" isLazy>
      <TabList justifyContent="center" p={4}>
        <DesignTab>BOM</DesignTab>
        <DesignTab>Layout</DesignTab>
        {isSchematicEnabled && <DesignTab>Schematic</DesignTab>}
      </TabList>
      <TabPanels display="flex" flex={1}>
        <DesignTabPanel>
          <BOM />
        </DesignTabPanel>
        <DesignTabPanel>
          <LayoutWithNotes />
        </DesignTabPanel>
        {isSchematicEnabled && (
          <DesignTabPanel>
            <SchematicWithWiringList />
          </DesignTabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};
