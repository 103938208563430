import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Backshell, PartType } from '@senrasystems/senra-ui';
import CompatibleAccessoryTable from './CompatibleAccessoryTable.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import { HStack, Text } from '@chakra-ui/react';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing: boolean;
}

const CompatibleBackshellTable = ({ editing }: Props) => {
  return (
    <FormControl>
      <HStack>
        <FormLabel id="BackshellLabel">
          <Text>Compatible Backshells</Text>
        </FormLabel>
      </HStack>
      <CompatibleAccessoryTable
        labelId="BackshellLabel"
        partType={PartType.BACKSHELL}
        editing={editing}
        maxIncluded={1}
        customColumns={[
          {
            header: 'Shielded',
            renderFn: (part) => {
              return (
                <EditableFormText label={null} editing={editing}>
                  <TextInput
                    maxWidth="200px"
                    formControlProps={{ isDisabled: true }}
                    value={(part as Backshell).shielded ? 'Yes' : 'No'}
                  />
                </EditableFormText>
              );
            },
          },
        ]}
        aria-labelledby="includedBackshellLabel"
      />
    </FormControl>
  );
};

export default CompatibleBackshellTable;
