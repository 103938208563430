import { useCallback, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDesign } from '../../hooks/useDesign.tsx';
import ConnectionItem from './components/ConnectionItem.tsx';
import { Connection, isConnectionTarget, UUID } from '@senrasystems/senra-ui';
import { useWiringList } from './hooks/useWiringList.tsx';
import { ConnectorLabel } from './components/ConnectorLabel.tsx';
import { ConnectionListHeader } from './components/ConnectionListHeader.tsx';

/**
 * WiringList component displays the selected design part and a list of connections.
 * @constructor
 */
const WiringList = () => {
  // Get the selected design part from the context.
  const { selectedDesignPart, selectedConnection, setSelectedConnection, isLoading, error } = useDesign();

  // Get this map of connections by source ID to lookup existing connections.
  const { wiringList } = useWiringList();

  // State for the active connection point being edited.
  const [activeEditRow, setActiveEditRow] = useState<UUID | null>(null);

  // State for the draft connection to add, the value is the row id the draft will be placed under.
  const [draftRow, setDraftRow] = useState<UUID | null>(null);

  // Exit editing.
  const stopEditing = useCallback(() => {
    setActiveEditRow(null);
    setDraftRow(null);
  }, []);

  // Handle selecting a connection.
  const handleSelectConnection = useCallback(
    (connection: Connection | undefined) => {
      if (connection) {
        setSelectedConnection(connection);
      }
    },
    [setSelectedConnection],
  );

  // If the design is loading or there is an error, return null.
  if (isLoading || error) {
    return null;
  } else if (!selectedDesignPart || !isConnectionTarget(selectedDesignPart)) {
    // Else if the selected design part is not a connection target, display a message
    return (
      <Box p={4} h="40vh" color="gray.600">
        <Text>No connection target selected. Select a valid part to view schematic.</Text>
      </Box>
    );
  }

  return (
    <Flex h="40vh" flexDirection="column">
      <ConnectorLabel connector={selectedDesignPart} />
      <Flex flex={1} flexDirection="column" overflow="auto">
        <ConnectionListHeader />
        <Box flex={1} overflow="auto">
          {wiringList.map((row) => {
            const isEditing = activeEditRow === row.id;
            const isAnyConnectionEditing = !!activeEditRow || !!draftRow;

            return (
              <Box
                key={row.id}
                role="group"
                color="gray.500"
                borderBottom="1px solid"
                borderColor="gray.200"
                bg={
                  !isEditing &&
                  selectedConnection &&
                  row.existingConnection &&
                  row.existingConnection.id === selectedConnection.id
                    ? 'blue.100'
                    : 'transparent'
                }
                onClick={() => {
                  if (!activeEditRow) {
                    handleSelectConnection(row.existingConnection);
                  }
                }}
              >
                <ConnectionItem
                  id={row.id}
                  connectionPoint={row.connectionPoint}
                  connection={row.existingConnection}
                  isEditing={isEditing}
                  isAnyConnectionEditing={isAnyConnectionEditing}
                  startEditing={setActiveEditRow}
                  stopEditing={stopEditing}
                  onAdd={setDraftRow}
                />
                {draftRow && draftRow === row.id && (
                  <ConnectionItem
                    id={'draft'}
                    connectionPoint={row.connectionPoint}
                    isAnyConnectionEditing={isAnyConnectionEditing}
                    stopEditing={stopEditing}
                    isEditing
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Flex>
    </Flex>
  );
};

export default WiringList;
