import {
  BackshellData,
  CableData,
  ConnectorData,
  ContactData,
  DesignPart,
  GenericData,
  PartType,
  PassiveData,
  SpliceData,
  WireData,
} from '@senrasystems/senra-ui';

export const usePartData = (part: DesignPart) => {
  // These are common field/value pairs for all part types.
  const getCommonData = () => {
    const partData = part.partData;
    return [
      { field: 'Type', value: partData.type },
      { field: 'Part Number', value: partData.partNumber },
      { field: 'Manufacturers', value: partData.manufacturers?.map((m) => m.name).join(', ') || 'N/A' },
      { field: 'Confidence', value: partData.confidence },
      { field: 'Description', value: partData.description },
    ];
  };

  const getBackshellData = () => {
    const backshellData = part.partData as BackshellData;
    return [...getCommonData(), { field: 'Shielded', value: backshellData.shielded }];
  };

  const getCableData = () => {
    const cableData = part.partData as CableData;
    return [
      ...getCommonData(),
      { field: 'Jacket', value: cableData.jacket },
      { field: 'Shielded', value: cableData.shielded },
      { field: 'Shield Type', value: cableData.shieldType },
      { field: 'Part Wires', value: cableData.partWires?.map((partWire) => partWire.wire?.gauge).join(', ') },
    ];
  };

  // Generate field/value pairs to display data for a Connector.
  const getConnectorData = () => {
    const connectorData = part.partData as ConnectorData;
    return [
      ...getCommonData(),
      { field: 'Insert Arrangement', value: connectorData.insertArrangement?.name },
      { field: 'Shielded', value: connectorData.shielded },
      { field: 'Accepts Backshell', value: connectorData.acceptsBackshell },
      { field: 'Accepts Contacts', value: connectorData.acceptsContacts },
      { field: 'Included Accessories', value: connectorData?.accessories.join(', ') },
    ];
  };

  // Generate field/value pairs to display data for a Contact.
  const getContactData = () => {
    const contactData = part.partData as ContactData;
    return [
      ...getCommonData(),
      { field: 'Gender', value: contactData.gender },
      { field: 'Gauge Min AWG', value: contactData.gaugeMinAwg },
      { field: 'Gauge Max AWG', value: contactData.gaugeMaxAwg },
      { field: 'Termination', value: contactData.termination },
    ];
  };

  // Generate field/value pairs to display data for a Generic.
  const getGenericData = () => {
    const genericData = part.partData as GenericData;
    return [
      ...getCommonData(),
      { field: 'Subtype', value: genericData.subtype },
      { field: 'Generic Part Unit', value: genericData.genericPartUnit },
    ];
  };

  // Generate field/value pairs to display data for a Passive.
  const getPassiveData = () => {
    const passiveData = part.partData as PassiveData;
    return [
      ...getCommonData(),
      { field: 'Subtype', value: passiveData.subtype },
      { field: 'Passive Value', value: passiveData.passiveValue },
      { field: 'Accepts Contacts', value: passiveData.acceptsContacts },
    ];
  };

  // Generate field/value pairs to display data for a Pigtail.
  const getPigtailData = () => {
    return [...getCommonData()];
  };

  // Generate field/value pairs to display data for a Splice.
  const getSpliceData = () => {
    const spliceData = part.partData as SpliceData;
    return [
      ...getCommonData(),
      { field: 'Subtype', value: spliceData.subtype },
      { field: 'Gauge Min AWG', value: spliceData.gaugeMinAwg },
      { field: 'Gauge Max AWG', value: spliceData.gaugeMaxAwg },
      { field: 'Insulated', value: spliceData.insulated },
      { field: 'Made On Assembly', value: spliceData.madeOnAssembly },
      { field: 'Accepts Contacts', value: spliceData.acceptsContacts },
    ];
  };

  // Generate field/value pairs to display data for a Wire.
  const getWireData = () => {
    const wireData = part.partData as WireData;
    return [
      ...getCommonData(),
      { field: 'Cable Only Wire', value: wireData.cableOnlyWire },
      { field: 'Colors', value: wireData.colors?.join(', ') },
      { field: 'Gauge', value: wireData.gauge },
    ];
  };

  // Return the field/value pairs based on the part type.
  const getFieldsByType = () => {
    switch (part.partData.type) {
      case PartType.BACKSHELL:
        return getBackshellData();
      case PartType.CABLE:
        return getCableData();
      case PartType.CONNECTOR:
        return getConnectorData();
      case PartType.CONTACT:
        return getContactData();
      case PartType.GENERIC:
        return getGenericData();
      case PartType.PASSIVE:
        return getPassiveData();
      case PartType.PIGTAIL:
        return getPigtailData();
      case PartType.SPLICE:
        return getSpliceData();
      case PartType.WIRE:
        return getWireData();
      default:
        return [...getCommonData()];
    }
  };

  return getFieldsByType();
};
