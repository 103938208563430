import { Node, NodeProps } from '@xyflow/react';
import { Box } from '@chakra-ui/react';
import { BreakoutPointShape } from './BreakoutPointShape.tsx';
import { CenterHandle } from '../common/CenterHandle.tsx';
import { getNodeColor } from '../../../utils/common.ts';
import { HandleTypes } from '../../../types/handles.ts';

export type BreakoutPointNodeData = {
  mergeCandidate: boolean;
};

export const defaultBreakoutPointNodeData: BreakoutPointNodeData = {
  mergeCandidate: false,
};

export type BreakoutPointNode = Node<BreakoutPointNodeData>;

/**
 * Breakout point node component.
 * @param props
 * @constructor
 */
export const BreakoutPointNode = (props: NodeProps<BreakoutPointNode>) => {
  const { selected } = props;

  return (
    <Box position="relative">
      <BreakoutPointShape color={getNodeColor(selected)} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Measurement} />
      <CenterHandle id={HandleTypes.Note} />
    </Box>
  );
};
