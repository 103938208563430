import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import PartSearchList from './components/PartSearchList.tsx';
import MultiSelectFilter from '../../components/MultiSelectFilter.tsx';
import { PartType, translatePartType } from '@senrasystems/senra-ui';
import { usePartSearch } from '../../hooks/usePartSearch.tsx';
import { BiSearch } from 'react-icons/bi';
import { PartSearchModalProps } from '../../hooks/usePartSearchModal.tsx';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  modalProps: PartSearchModalProps;
}

/**
 * PartsSearchModal component displays a modal for searching parts.
 * The search is debounced by 500ms.
 * @constructor
 */
const PartSearchModal = ({ isOpen, closeModal, modalProps }: Props) => {
  const { partTypeFilters } = modalProps;

  // State to manage what part is expanded in the search results
  const [expandedPartId, setExpandedPartId] = useState<string | null>(null);
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  // State to manage if the "create part" button is shown
  const [showCreateButton, setShowCreateButton] = useBoolean();

  // State for the input value (find part input)
  const [inputValue, setInputValue] = useState('');

  // Custom hook for finding parts
  const { parts, findPart, setFilters, isSuccess } = usePartSearch({ designToolSearch: true });
  const [firstFilter, setFirstFilter] = useState(PartType.BACKSHELL);

  useEffect(() => {
    if (isSuccess) {
      setShowCreateButton.on();
    }
  }, [isSuccess, setShowCreateButton]);

  useEffect(() => {
    setFilters(partTypeFilters);
    setFirstFilter(partTypeFilters[0]);
    setShowCreateButton.on();
  }, [partTypeFilters, setFilters, setFirstFilter, setShowCreateButton]);

  const setSearchValue = (value: string = '') => {
    setInputValue(value);
    setExpandedIndex(-1);
    setExpandedPartId(null);
    findPart(value);
  };

  // Handle input change and call the debounced search function
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleCLose = () => {
    setInputValue('');
    setShowCreateButton.on();
    setSearchValue('');
    closeModal();
  };

  // Find part input
  const renderFindPartInput = () => {
    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none" height="100%" alignItems="center">
          <Icon as={BiSearch} boxSize="1.75rem" />
        </InputLeftElement>
        <Input
          id="find-part-input"
          type="text"
          placeholder="Type to find a part..."
          height="60px"
          fontSize="1.2em"
          variant="unstyled"
          value={inputValue}
          onChange={handleInputChange}
          autoComplete="off"
        />
      </InputGroup>
    );
  };

  // Filters
  const renderFilters = () => {
    return (
      <MultiSelectFilter
        options={Object.values(PartType)}
        selectedOptions={partTypeFilters}
        isToggleable={partTypeFilters.length === Object.values(PartType).length}
        onFilter={(selectedOptions) => {
          const partTypes = selectedOptions.filter((str): str is PartType =>
            Object.values(PartType).includes(str as PartType),
          );
          setFilters(partTypes);
          setFirstFilter(partTypes[0] ?? PartType.BACKSHELL);
        }}
        optionTransform={translatePartType}
      />
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCLose} size="custom">
      <ModalOverlay backgroundColor="transparent" />
      <ModalContent w="800px" bg="gray.100">
        <ModalBody w="full" p={0}>
          <VStack align="stretch">
            <Box px={4} pt={4} borderRadius="md">
              {renderFindPartInput()}
            </Box>
            <Box px={4} pb={2} ml="auto">
              {renderFilters()}
            </Box>
            <Box
              maxH="calc(100vh - 200px)"
              overflowY="auto"
              w="full"
              borderTop={parts.length > 0 ? '1px solid' : '0px'}
              borderColor="gray.300"
            >
              <PartSearchList
                parts={parts}
                setSearchValue={setSearchValue}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                expandedPartId={expandedPartId}
                setExpandedPartId={setExpandedPartId}
                showCreateButton={showCreateButton}
                filterType={firstFilter}
              />
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PartSearchModal;
