import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { BOMItem, Connection, Design, DesignPart, UUID } from '@senrasystems/senra-ui';
import { keyBy } from 'lodash';
import { useDesignId } from './useDesignId.tsx';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useDesignQuery } from '../api/queries.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useDesignParts } from './useDesignParts.tsx';
import { useBOM } from './useBOM.tsx';

// Context type for design
export type DesignContextType = {
  designId: UUID;
  design: Design | undefined;
  designPartsMap: Map<UUID, DesignPart>;
  bom: BOMItem[];
  bomByPartNumber: { [key: UUID]: BOMItem };
  isSelectedDesignPart: (part: DesignPart) => boolean;
  selectedDesignPart: DesignPart | null;
  setSelectedDesignPart: (part: DesignPart | null) => void;
  isSelectedBOMItem: (part: DesignPart) => boolean;
  selectedBOMItem: DesignPart | null;
  setSelectedBOMItem: (part: DesignPart | null) => void;
  isSelectedConnection: (connection: Connection) => boolean;
  selectedConnection: Connection | null;
  setSelectedConnection: (connection: Connection | null) => void;
  lockedAt: string | null;
  isLoading: boolean;
  error: Error | null;
};

// Create a context for design
const DesignContext = createContext<DesignContextType | undefined>(undefined);

// Hook to use the design context
export const useDesign = () => {
  const context = useContext(DesignContext);
  if (context === undefined) {
    throw new Error('useDesign must be used within a DesignProvider');
  }
  return context;
};

// Provider to manage design
export const DesignProvider = ({ children }: { children: React.ReactNode }) => {
  // Get navigate from useNavigate hook
  const navigate = useNavigate();
  // Get the design ID from the URL params
  const designId = useDesignId();

  // Get the design part ID from the URL params (for deep linking a selected design part)
  const { designPartId } = useParams<{ designPartId: string }>();

  // Get the design data
  const { data: design, isLoading, error } = useDesignQuery(designId);
  const { designPartsMap } = useDesignParts();

  // Get the BOM
  const { bom } = useBOM(designId);
  const bomByPartNumber: { [key: string]: BOMItem } = useMemo(() => keyBy(bom, 'partNumber'), [bom]);

  // State to manage selected design part
  const [selectedDesignPart, setSelectedDesignPart] = useState<DesignPart | null>(null);
  const isSelectedDesignPart = (part: DesignPart) => selectedDesignPart?.id === part.id;

  // State to manage selected BOM item
  const [selectedBOMItem, setSelectedBOMItem] = useState<DesignPart | null>(null);
  const isSelectedBOMItem = (part: DesignPart) => selectedBOMItem?.partData.partNumber === part.partData.partNumber;

  // State to manage selected connection
  const [selectedConnection, setSelectedConnection] = useState<Connection | null>(null);
  const isSelectedConnection = (connection: Connection) => selectedConnection?.id === connection.id;

  useEffect(() => {
    if (design && designPartId) {
      // Find the selected design part, and set it
      const part = design.designParts.find((part) => part.id === designPartId);
      if (part) {
        setSelectedDesignPart(part);
      }
    }
  }, [design, designPartId]);

  useEffect(() => {
    if (selectedDesignPart) {
      // Generate the URL for the selected design part
      const url = generatePath(RouteNames.DESIGNS.DESIGN_PART, { designId, designPartId: selectedDesignPart.id });
      // Navigate to the URL
      navigate(url, { replace: true });
    }
  }, [selectedDesignPart, navigate, designId]);

  // Value for the context
  const value = {
    designId,
    design,
    designPartsMap,
    bom,
    bomByPartNumber,
    isSelectedDesignPart,
    selectedDesignPart,
    setSelectedDesignPart,
    isSelectedBOMItem,
    selectedBOMItem,
    setSelectedBOMItem,
    isSelectedConnection,
    selectedConnection,
    setSelectedConnection,
    lockedAt: design?.lockedAt || null,
    isLoading,
    error,
  };

  return <DesignContext.Provider value={value}>{children}</DesignContext.Provider>;
};
