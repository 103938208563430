import { FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Text, Input, InputProps } from '@chakra-ui/react';

interface Props extends InputProps {
  text: string;
  onEdit: (value: string) => void;
  editAction?: 'dblclick' | 'click';
}

const EditableText = ({ text, onEdit, editAction = 'dblclick', ...rest }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);
  const inputRef = useRef<HTMLInputElement>(null);

  const startEditing = () => {
    setIsEditing(true);
  };

  // If this is currently not editing, it should follow the text
  useEffect(() => {
    if (!isEditing && value !== text) {
      setValue(text);
    }
  }, [isEditing, setValue, text, value]);

  useEffect(() => {
    if (isEditing) {
      // Use a timeout to ensure focus is applied after rendering
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.select();
        }
      }, 0);
    }
  }, [isEditing]);

  const handleChange = (event: FocusEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleOnBlur = () => {
    setIsEditing(false);
    if (value !== text) {
      onEdit(value);
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleOnBlur();
    }
  };

  return isEditing ? (
    <Input
      ref={inputRef} // Attach the ref to the input
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleOnBlur}
      onKeyDown={handleOnKeyDown}
      autoFocus
      autoComplete="off"
      {...rest}
    />
  ) : (
    <Text
      onClick={editAction === 'click' ? startEditing : undefined}
      onDoubleClick={editAction === 'dblclick' ? startEditing : undefined}
    >
      {text}
    </Text>
  );
};

export default EditableText;
