import { MouseEvent, useState } from 'react';
import { defaultLayoutConfig } from '../config.ts';
import { useLayoutBuilder } from './useLayoutBuilder.tsx';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import { useLayoutActions } from './actions/useLayoutActions.tsx';
import { isBreakoutPointNode, isDesignPartNode } from '../types.ts';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { useUpdateLayoutEffects } from './useUpdateLayoutEffects.tsx';
import { useUpdateConductorLengths } from './useUpdateConductorLengths.tsx';

/**
 * Hook to manage ReactFlow events and context menus.
 */
export const useLayout = (layoutLoaded: boolean) => {
  // Settings for the layout
  const [config, setConfig] = useState(defaultLayoutConfig);

  // Design Context
  const { getDesignPartById } = useDesignParts();
  const { lockedAt, setSelectedDesignPart } = useDesign();

  // ReactFlow API methods
  const { getNodes, getEdges, getIntersectingNodes } = useReactFlow();

  // Graph builder
  const { executeGraphOperation, saveLayout, updateLayout } = useLayoutBuilder();

  // Update layout notes
  useUpdateLayoutEffects(updateLayout, layoutLoaded);

  // Function to update conductor lengths
  const updateConductorLengths = useUpdateConductorLengths();

  // Flag to remember if there were intersections (this is for drag and merge control points)
  const [hadIntersections, setHadIntersections] = useState(false);

  // Custom hook for all context menu operations
  const { closeAllMenus, onGraphElementContextMenu, onPaneContextMenu, contextMenus } = useLayoutActions();

  // Pane click handler
  const onPaneClick = (event: MouseEvent) => {
    event.preventDefault();
    closeAllMenus();
    // eslint-disable-next-line no-console
    console.debug('Nodes:', getNodes());
    // eslint-disable-next-line no-console
    console.debug('Edges:', getEdges());
  };

  const onNodeClick = (_event: MouseEvent, node: Node) => {
    // eslint-disable-next-line no-console
    console.debug('Node clicked:', node);
    if (isDesignPartNode(node)) {
      const designPart = getDesignPartById(node.data.designPartId);
      if (designPart) {
        setSelectedDesignPart(designPart);
      }
    }
  };

  const onEdgeClick = (_event: MouseEvent, edge: Edge) => {
    // eslint-disable-next-line no-console
    console.debug('Edge clicked:', edge);
  };

  // Node drag handler
  const onNodeDrag = (_event: MouseEvent, node: Node) => {
    closeAllMenus();
    if (lockedAt || !isBreakoutPointNode(node)) {
      return;
    }

    // Get the nodes that intersect with the dragged node
    const intersectingNodes = getIntersectingNodes(node);
    const intersections = intersectingNodes.filter(isBreakoutPointNode).map((n) => n.id);
    const hasIntersections = intersections.length > 0;

    // Execute the operation regardless, as breakoutIntersections will be an empty array if there are no intersections
    if (hasIntersections || hadIntersections) {
      executeGraphOperation({ type: 'HighlightBreakoutPoints', params: { nodeId: node.id, intersections } });
    }

    // Update the hadIntersections state based on whether intersections exist
    setHadIntersections(hasIntersections);
  };

  // Node drag stop handler
  const onNodeDragStop = (_event: MouseEvent, node: Node) => {
    // Don't do anything if the design is locked
    if (lockedAt) {
      return;
    }

    // Save the layout
    saveLayout(getNodes(), getEdges());

    // Check if the dragged node can be merged with another node
    if (isBreakoutPointNode(node)) {
      // Get the nodes that intersect with the dragged node
      const intersectingNodes = getIntersectingNodes(node);

      // Check if there is exactly one intersection, and it's of the same type as the dragged node
      const canMerge = intersectingNodes.length === 1 && isBreakoutPointNode(intersectingNodes[0]);

      if (canMerge) {
        // Merge the breakout points
        const updatedGraph = executeGraphOperation({
          type: 'MergeBreakoutPoints',
          params: { fromNodeId: node.id, toNodeId: intersectingNodes[0].id },
        });

        // Update conductor lengths to the backend
        updateConductorLengths(updatedGraph);
      }
    }
  };

  return {
    config,
    setConfig,
    onPaneContextMenu,
    onGraphElementContextMenu,
    closeAllMenus,
    onPaneClick,
    onNodeClick,
    onEdgeClick,
    onNodeDrag,
    onNodeDragStop,
    contextMenus,
  };
};
