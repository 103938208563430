import { Navigate, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ErrorPage from '@web/components/errors/ErrorPage';
import NotFound from '@web/components/errors/NotFound';
import Logout from './routes/Logout';
import Profile from './routes/Profile';
import CompanySettings from './routes/CompanySettings';
import Root from './routes/Root';
import CreateOrder from './routes/order/new';
import OrderDashboard from './routes/order/dashboard';
import OrderDetails from './routes/order/details';
import AdminManualQuoteUpload from './routes/admin/AdminManualQuoteUpload.tsx';
import AdminDeclineOrder from './routes/admin/AdminDeclineOrder.tsx';
import UploadPurchaseOrder from './routes/order/UploadPurchaseOrder';
import AuthenticationGuard from './components/auth/AuthenticationGuard';
import { useAuth0 } from '@auth0/auth0-react';
import { apiGetProfile } from './api/profile-api.ts';
import AdminUsers from './routes/admin/users/AdminUsers';
import AdminNewUser from './routes/admin/users/AdminNewUser';
import AdminEditUser from './routes/admin/users/AdminEditUser';
import AdminTenants from './routes/admin/tenants/AdminTenants';
import AdminNewTenant from './routes/admin/tenants/AdminNewTenant';
import AdminEditTenant from './routes/admin/tenants/AdminEditTenant';
import {
  BackshellsDashboard,
  CablesDashboard,
  ConnectorsDashboard,
  ContactsDashboard,
  GenericDashboard,
  InsertArrangementsDashboard,
  OverwrapsDashboard,
  PartsLibraryLayout,
  PassivesDashboard,
  PigtailsDashboard,
  SplicesDashboard,
  WiresDashboard,
} from './apps/Parts-Library';
import Register from './routes/Register';
import { DefaultLayout } from './components/layouts/DefaultLayout';
import { AssemblyNavigator, BOM, Design, DesignLayout, Designs, Layout, Schematic, WiringList } from './apps/Design';
import theme from './apps/themes.ts';
import { ChakraProvider } from '@chakra-ui/react';
import { doesUserHaveRole, Role } from '@senrasystems/senra-ui';
import { envVars, Vars } from './common/environment';
import size from 'lodash/size';
import { ReactFlowProvider } from '@xyflow/react';
import { RouteNames } from '@web/consts/routeNames.ts';

const NullComponent = () => null;

// Used by <Root /> to generate page titles for routes.
export const pageTitles = new Map<string, string[] | null[]>([
  [RouteNames.ROOT, [null]],
  [RouteNames.NOT_FOUND, ['Not Found']],
  [RouteNames.SIGN_OUT, ['Sign Out']],
  [RouteNames.PROFILE, ['Profile']],
  [RouteNames.COMPANY_PROFILE, ['Company Profile']],
  [RouteNames.PARTS.BACKSHELLS, ['Backshells', 'Part Library']],
  [RouteNames.PARTS.CABLES, ['Cables', 'Part Library']],
  [RouteNames.PARTS.CONNECTORS, ['Connectors', 'Part Library']],
  [RouteNames.PARTS.CONTACTS, ['Contacts', 'Part Library']],
  [RouteNames.PARTS.WIRES, ['Wires', 'Part Library']],
  [RouteNames.DESIGNS.INDEX, ['Designs', 'Design Tool']],
  [RouteNames.DESIGNS.DESIGN, ['Design', 'Design Tool']],
  [RouteNames.DESIGNS.DESIGN_PARTS, ['Assembly Navigator', 'Design Tool']],
  [RouteNames.DESIGNS.BOM, ['BOM', 'Design Tool']],
  [RouteNames.ORDERS.INDEX, ['Orders']],
  [RouteNames.ORDERS.NEW, ['New Order']],
  [RouteNames.ORDERS.SHOW, ['Order Details']],
  [RouteNames.ORDERS.UPLOAD_PURCHASE_ORDER, ['Upload Purchase Order']],
  [RouteNames.TENANTS.INDEX, ['Admin Tenants']],
  [RouteNames.TENANTS.NEW, ['New Tenant']],
  [RouteNames.USERS.INDEX, ['Admin Users']],
  [RouteNames.USERS.NEW, ['New User']],
  [RouteNames.MANUAL_QUOTE_UPLOAD, ['Upload a Quote', 'Admin']],
  [RouteNames.DECLINE_ORDER, ['Decline an Order', 'Admin']],
]);

export function AppRoutes() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <ChakraProvider theme={theme}>
        <SentryRoutes>
          <Route index element={<AuthenticationGuard component={NullComponent} />} />
          <Route path={RouteNames.REGISTER} element={<Register />} />
          <Route path="*" element={<AuthenticationGuard component={NullComponent} />} />
        </SentryRoutes>
      </ChakraProvider>
    );
  }

  void apiGetProfile().then((user) => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        tenant: user.tenant.name,
      });
    }

    if (doesUserHaveRole(user, Role.TECHNICIAN) && size(user?.roles) === 1) {
      window.location.href = `${envVars.get(Vars.FactoryWebBaseUrl)}`;
    }
  });

  return (
    <SentryRoutes>
      <Route path={RouteNames.ROOT} element={<Root />} errorElement={<ErrorPage />}>
        <Route path={RouteNames.SIGN_OUT} element={<Logout />} />
        <Route path={RouteNames.NOT_FOUND} element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<DefaultLayout />}>
          <Route path={RouteNames.PROFILE} element={<Profile />} />
          <Route path={RouteNames.COMPANY_PROFILE} element={<CompanySettings />} />
          <Route path={RouteNames.ORDERS.INDEX} element={<OrderDashboard />} />
          <Route path={RouteNames.ORDERS.NEW} element={<CreateOrder />} />
          <Route path={RouteNames.ORDERS.SHOW} element={<OrderDetails />} />
          <Route path={RouteNames.ORDERS.UPLOAD_PURCHASE_ORDER} element={<UploadPurchaseOrder />} />
          <Route path={RouteNames.TENANTS.INDEX} element={<AdminTenants />} />
          <Route path={RouteNames.TENANTS.NEW} element={<AdminNewTenant />} />
          <Route path={RouteNames.TENANTS.EDIT} element={<AdminEditTenant />} />
          <Route path={RouteNames.USERS.INDEX} element={<AdminUsers />} />
          <Route path={RouteNames.USERS.NEW} element={<AdminNewUser />} />
          <Route path={RouteNames.USERS.EDIT} element={<AdminEditUser />} />
          <Route path={RouteNames.MANUAL_QUOTE_UPLOAD} element={<AdminManualQuoteUpload />} />
          <Route path={RouteNames.DECLINE_ORDER} element={<AdminDeclineOrder />} />
        </Route>
        <Route element={<PartsLibraryLayout />}>
          <Route path={RouteNames.PARTS.INDEX} element={<Navigate to={RouteNames.PARTS.CONNECTORS} replace={true} />} />
          <Route path={RouteNames.PARTS.BACKSHELL} element={<BackshellsDashboard />} />
          <Route path={RouteNames.PARTS.BACKSHELLS} element={<BackshellsDashboard />} />
          <Route path={RouteNames.PARTS.CABLE} element={<CablesDashboard />} />
          <Route path={RouteNames.PARTS.CABLES} element={<CablesDashboard />} />
          <Route path={RouteNames.PARTS.CONNECTOR} element={<ConnectorsDashboard />} />
          <Route path={RouteNames.PARTS.CONNECTORS} element={<ConnectorsDashboard />} />
          <Route path={RouteNames.PARTS.CONTACT} element={<ContactsDashboard />} />
          <Route path={RouteNames.PARTS.CONTACTS} element={<ContactsDashboard />} />
          <Route path={RouteNames.PARTS.GENERIC} element={<GenericDashboard />} />
          <Route path={RouteNames.PARTS.GENERICS} element={<GenericDashboard />} />
          <Route path={RouteNames.PARTS.INSERT_ARRANGEMENT} element={<InsertArrangementsDashboard />} />
          <Route path={RouteNames.PARTS.INSERT_ARRANGEMENTS} element={<InsertArrangementsDashboard />} />
          <Route path={RouteNames.PARTS.PASSIVE} element={<PassivesDashboard />} />
          <Route path={RouteNames.PARTS.PASSIVES} element={<PassivesDashboard />} />
          <Route path={RouteNames.PARTS.PIGTAIL} element={<PigtailsDashboard />} />
          <Route path={RouteNames.PARTS.PIGTAILS} element={<PigtailsDashboard />} />
          <Route path={RouteNames.PARTS.SPLICE} element={<SplicesDashboard />} />
          <Route path={RouteNames.PARTS.SPLICES} element={<SplicesDashboard />} />
          <Route path={RouteNames.PARTS.WIRE} element={<WiresDashboard />} />
          <Route path={RouteNames.PARTS.WIRES} element={<WiresDashboard />} />
          <Route path={RouteNames.PARTS.OVERWRAP} element={<OverwrapsDashboard />} />
          <Route path={RouteNames.PARTS.OVERWRAPS} element={<OverwrapsDashboard />} />
        </Route>
        <Route element={<DesignLayout />}>
          <Route path={RouteNames.DESIGNS.INDEX} element={<Designs />} />
          <Route path={RouteNames.DESIGNS.DESIGN} element={<Design />} />
          <Route path={RouteNames.DESIGNS.DESIGN_PART} element={<Design />} />
          <Route path={RouteNames.DESIGNS.DESIGN_PARTS} element={<AssemblyNavigator />} />
          <Route path={RouteNames.DESIGNS.BOM} element={<BOM />} />
          <Route
            path={RouteNames.DESIGNS.LAYOUT}
            element={
              <ReactFlowProvider>
                <Layout />
              </ReactFlowProvider>
            }
          />
          <Route path={RouteNames.DESIGNS.SCHEMATIC} element={<Schematic />} />
          <Route path={RouteNames.DESIGNS.WIRING_LIST} element={<WiringList />} />
        </Route>
      </Route>
    </SentryRoutes>
  );
}
