import ManagedWindow from '../../components/ManagedWindow.tsx';
import CoordinatePanel from './components/CoordinatePanel.tsx';
import { Box } from '@chakra-ui/react';
import {
  Background,
  BackgroundVariant,
  ConnectionMode,
  Controls,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from '@xyflow/react';
import { edgeTypes, emptyEdges, emptyNodes, nodeTypes } from './types.ts';
import '@xyflow/react/dist/style.css';
import { useLayout } from './hooks/useLayout.tsx';
import { defaultLayoutConfig } from './config.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useDesignOverview } from '../../hooks/useDesignOverview.tsx';
import { useLoadLayout } from './hooks/useLoadLayout.tsx';

/**
 * Entry point for the Layout feature.
 * @constructor
 */
const Layout = () => {
  // Get lockedAt from the useDesignOverview hook
  const { lockedAt } = useDesignOverview();

  // Initialize the nodes and edges state
  const [nodes, setNodes, onNodesChange] = useNodesState(emptyNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(emptyEdges);

  // Initialize Layout
  const layoutLoaded = useLoadLayout(setNodes, setEdges);

  // Get config, init, context menus, and context menu handlers from the useLayout hook
  const {
    config,
    onPaneContextMenu,
    onGraphElementContextMenu,
    closeAllMenus,
    onPaneClick,
    onNodeClick,
    onEdgeClick,
    onNodeDrag,
    onNodeDragStop,
    contextMenus,
  } = useLayout(layoutLoaded);

  return (
    <ManagedWindow title="Layout" routeName={RouteNames.DESIGNS.LAYOUT} actions={null}>
      {() => (
        <Box w="full" h="full">
          {/* Render the ReactFlow component */}
          <ReactFlow
            /* Config nodes */
            nodeTypes={nodeTypes}
            nodes={nodes}
            onNodesChange={onNodesChange}
            nodesConnectable={false}
            nodesDraggable={!lockedAt}
            /* Config edges */
            edgeTypes={edgeTypes}
            edges={edges}
            onEdgesChange={onEdgesChange}
            /* Click handlers */
            onPaneClick={onPaneClick}
            onPaneContextMenu={onPaneContextMenu}
            onNodeClick={onNodeClick}
            onNodeContextMenu={onGraphElementContextMenu}
            onEdgeClick={onEdgeClick}
            onEdgeContextMenu={onGraphElementContextMenu}
            /* Drag handlers */
            onMove={closeAllMenus}
            onNodeDrag={onNodeDrag}
            onNodeDragStop={onNodeDragStop}
            /* ReactFlow settings (fixed) */
            connectionMode={ConnectionMode.Loose}
            proOptions={{ hideAttribution: true }}
            zoomOnDoubleClick={false}
            panOnScroll={true}
            deleteKeyCode={null}
            /* ReactFlow settings (from config.ts) */
            defaultViewport={config.defaultViewport}
            minZoom={config.minZoom}
            maxZoom={config.maxZoom}
            nodeOrigin={config.nodeOrigin}
            snapToGrid={config.snapToGrid}
            snapGrid={config.snapGrid}
            style={config.style}
          >
            <Background
              color={defaultLayoutConfig.gridLinesColor}
              variant={BackgroundVariant.Lines}
              lineWidth={1}
              gap={defaultLayoutConfig.gridLinesGap}
            />
            <Controls showInteractive={false} position="bottom-left" />
            <CoordinatePanel position="bottom-right" />
          </ReactFlow>
          {/* Render all context menus */}
          {!lockedAt && contextMenus}
        </Box>
      )}
    </ManagedWindow>
  );
};

export default Layout;
