/* eslint-disable no-console */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import { NoteType } from '@senrasystems/senra-ui';
import { useLayoutData } from '../../../hooks/useLayoutData';
import { useDesignBuildNotes } from '../../../hooks/useDesignBuildNotes';
import { useConnections } from '../../../hooks/useConnections';
import { Graph } from '../types';
import { getUpdatedGraph } from '../utils/updateGraph';

type setNodesType = Dispatch<SetStateAction<Node[]>>;
type setEdgesType = Dispatch<SetStateAction<Edge[]>>;

const defaultViewport = { x: 0, y: 0, zoom: 1 };

export const useLoadLayout = (setNodes: setNodesType, setEdges: setEdgesType): boolean => {
  const [layoutLoaded, setLayoutLoaded] = useState(false);
  const { setViewport } = useReactFlow();

  const { layoutData, isSuccess: isLayoutDataLoaded } = useLayoutData();
  const { data: flagNotes } = useDesignBuildNotes(NoteType.FLAG);
  const { connections } = useConnections();

  // Initialize layout
  useEffect(() => {
    const loadLayout = async () => {
      if (layoutLoaded) {
        return;
      }

      // Wait for data to load before trying to set nodes and edges
      if (isLayoutDataLoaded && flagNotes && connections) {
        console.time('Load Layout');

        let updatedGraph: Graph = { nodes: [], edges: [] };

        try {
          const { nodes = [], edges = [], viewport } = layoutData;
          if (viewport) {
            console.debug('Loading viewport from design.layoutData', layoutData);
            await setViewport({ ...defaultViewport, ...viewport });
          } else {
            console.debug('No existing viewport found.');
          }

          updatedGraph = getUpdatedGraph({ nodes, edges, connections, flagNotes });
        } catch (error) {
          console.error('Failed to parse layout:', error);
          updatedGraph = getUpdatedGraph({ nodes: [], edges: [], connections, flagNotes });
          await setViewport(defaultViewport);
        } finally {
          console.timeEnd('Load Layout');
        }

        setNodes(updatedGraph.nodes);
        setEdges(updatedGraph.edges);
        setLayoutLoaded(true);
      }
    };

    void loadLayout();
  }, [setNodes, setEdges, layoutData, isLayoutDataLoaded, layoutLoaded, flagNotes, connections, setViewport]);

  return layoutLoaded;
};
