import { Connection, UUID } from '@senrasystems/senra-ui';
import { Bundle, Bundles, isSegmentEdge } from '../types.ts';
import { Edge } from '@xyflow/react';
import { SegmentEdgeData } from '../components/edges/SegmentEdge/SegmentEdge.tsx';

// ===============================
// Functions to work with bundles
// ===============================

/**
 * Creates a unique bundle ID based on the sorted combination of source and destination design part IDs. This ensures
 * that the same bundle ID is generated regardless of the order of the source and destination IDs.
 * @param sourceDesignPartId
 * @param destinationDesignPartId
 */
export const createBundleId = (sourceDesignPartId: string, destinationDesignPartId: string): string => {
  // Sort the IDs to ensure the same order regardless of source and destination
  const [sortedSourceId, sortedDestinationId] = [sourceDesignPartId, destinationDesignPartId].sort();
  return `${sortedSourceId}:${sortedDestinationId}`;
};

/**
 * Creates a new bundle object.
 * @param sourceDesignPartId
 * @param destinationDesignPartId
 * @param connections
 */
export const createBundle = (
  sourceDesignPartId: string,
  destinationDesignPartId: string,
  connections: Connection[] = [],
): Bundle => {
  const uniqueConnections = Array.from(new Set(connections.map((conn) => conn.id)))
    .map((id) => connections.find((conn) => conn.id === id))
    .filter((conn): conn is Connection => conn !== undefined);

  return {
    id: createBundleId(sourceDesignPartId, destinationDesignPartId),
    sourceDesignPartId,
    destinationDesignPartId,
    connections: uniqueConnections,
  };
};

/**
 * Adds a bundle to an array of bundles ensuring that only unique bundles are retained.
 * @param bundles
 * @param bundle
 */
export const addBundle = (bundles: Bundles, bundle: Bundle): Bundles => {
  if (hasBundle(bundles, bundle.id)) {
    return bundles;
  }

  return [...bundles, bundle];
};

/**
 * Adds a bundle to a segment edge directly by modifying the edges array.
 * @param edges - The array of edges in the graph.
 * @param edgeId - The ID of the edge to which the bundle will be added.
 * @param bundle - The bundle to add to the edge.
 */
export const addBundleToSegment = (edges: Edge[], edgeId: string, bundle: Bundle): void => {
  const edge = edges.find((edge) => edge.id === edgeId && isSegmentEdge(edge));
  if (edge) {
    const data = edge.data as SegmentEdgeData;
    data.bundles = addBundle(data.bundles, bundle);
  }
};

/**
 * Deletes a bundle from an array of bundles.
 * @param bundles
 * @param bundleId
 */
export const removeBundle = (bundles: Bundles, bundleId: string): Bundles => {
  return bundles.filter((bundle) => bundle.id !== bundleId);
};

/**
 * Removes a bundle from a segment edge directly by modifying the edges array.
 * If no bundles are left after removal, the edge is removed from the array.
 * @param edges - The array of edges in the graph.
 * @param edgeId - The ID of the edge from which the bundle will be removed.
 * @param bundleId - The ID of the bundle to remove.
 */
export const removeBundleFromSegment = (edges: Edge[], edgeId: string, bundleId: string): void => {
  const edgeIndex = edges.findIndex((edge) => edge.id === edgeId && isSegmentEdge(edge));
  if (edgeIndex !== -1) {
    const edge = edges[edgeIndex];
    const data = edge.data as SegmentEdgeData;
    data.bundles = removeBundle(data.bundles, bundleId);
    if (data.bundles.length === 0) {
      // Remove the edge if there are no bundles left
      edges.splice(edgeIndex, 1);
    }
  }
};

/**
 * Finds a bundle in an array of bundles.
 * @param bundles
 * @param bundleId
 */
export const findBundle = (bundles: Bundles, bundleId: string): Bundle | undefined => {
  return bundles.find((bundle) => bundle.id === bundleId);
};

/**
 * Checks if a bundle exists in an array of bundles.
 * @param bundles
 * @param bundleId
 */
export const hasBundle = (bundles: Bundles, bundleId: string): boolean => {
  return bundles.some((bundle) => bundle.id === bundleId);
};

/**
 * Merges two sets of bundles, ensuring that only unique bundles are retained.
 * @param bundles1
 * @param bundles2
 */
export const mergeBundles = (bundles1: Bundle[], bundles2: Bundle[]): Bundle[] => {
  const bundleMap = new Map<string, Bundle>();

  // Add all existing bundles to the map
  bundles1.forEach((bundle) => bundleMap.set(bundle.id, bundle));

  // Add and merge bundles from the second set
  bundles2.forEach((bundle) => {
    const existingBundle = bundleMap.get(bundle.id);
    if (existingBundle) {
      const uniqueConnections = new Set([...existingBundle.connections, ...bundle.connections]);
      existingBundle.connections = Array.from(uniqueConnections);
    } else {
      bundleMap.set(bundle.id, bundle);
    }
  });

  return Array.from(bundleMap.values());
};

// ==============================================
// Functions to work with connections in bundles
// ==============================================

/**
 * Adds a connection to a bundle. If the connection already exists in the bundle, the original bundle is returned.
 * @param bundle
 * @param connection
 */
export const addConnectionToBundle = (bundle: Bundle, connection: Connection): Bundle => {
  if (hasDuplicateConnection(bundle.connections, connection)) {
    return bundle;
  }

  return {
    ...bundle,
    connections: [...bundle.connections, connection],
  };
};

/**
 * Removes a connection from a bundle.
 * @param bundle
 * @param connectionId
 */
export const removeConnectionFromBundle = (bundle: Bundle, connectionId: UUID): Bundle => {
  return {
    ...bundle,
    connections: bundle.connections.filter((conn) => conn.id !== connectionId),
  };
};

/**
 * Updates a bundle with a new connection. If the bundle does not exist, the original array is returned.
 * @param bundles - An array of Bundle objects.
 * @param bundleId - The ID of the bundle to find and update.
 * @param connection - The connection to add to the bundle.
 * @returns A new array of bundles with the updated bundle if found, otherwise the original array.
 */
export const updateBundleWithConnection = (bundles: Bundle[], bundleId: string, connection: Connection): Bundle[] => {
  // Find the index of the bundle
  const bundleIndex = bundles.findIndex((b) => b.id === bundleId);

  // If the bundle exists, update it with the new connection
  if (bundleIndex !== -1) {
    // Update the bundle at the found index
    const updatedBundles = [...bundles];
    updatedBundles[bundleIndex] = addConnectionToBundle(bundles[bundleIndex], connection);
    return updatedBundles;
  }

  // Return the original bundles if no update was made
  return bundles;
};

/**
 * Checks if a connection already exists in a bundle.
 * @param connections - An array of connections.
 * @param connection - The connection to check.
 * @returns True if the connection already exists in the bundle, otherwise false.
 */
export const hasDuplicateConnection = (connections: Connection[], connection: Connection): boolean => {
  return connections.some(
    (conn) =>
      conn.id === connection.id &&
      ((conn.sourceId === connection.sourceId && conn.destinationId === connection.destinationId) ||
        (conn.sourceId === connection.destinationId && conn.destinationId === connection.sourceId)),
  );
};
